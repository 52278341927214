import { useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { Col, Row, Button, Spin } from "antd";
import axios from "axios";
import moment from "moment";

import UserBar from "../../../layout/UserBar/UserBar";
import quotasSVG from "../../../assets/icons/quotas-verde.svg";
import quotasRedSVG from "../../../assets/icons/quotas-red.svg";
import quotasYellowSVG from "../../../assets/icons/quotas-amarelo.svg";
import legalSVG from "../../../assets/icons/Legal-Department.svg";
import ModalPaymentInfo from "../../../components/UserComponents/ModalPaymentInfo/ModalPaymentInfo";
import PaymentSteps from "../../../components/UserComponents/PaymentSteps/PaymentSteps";
import QuotesModal from "../../../components/UserComponents/QuotesModal/QuotesModal";
import ModalRegularization from "../../../components/UserComponents/ModalRegularization/ModalRegularization";
import endoinpts from "../../../utils/endoinpts";
import DashboardEvents from "../../../components/UserComponents/DashboardEvents/DashboardEvents";
import DashboardCertificates from "../../../components/UserComponents/DashboardCertificates/DashboardCertificates";
import DashboardListEvents from "../../../components/UserComponents/DashboardListEvents/DashboardListEvents";
import "./Home.css";

function Home() {
  const { user } = useOutletContext();
  const { handleStopLoading } = useOutletContext();

  const [isPaymentNeeded, setIsPaymentNeeded] = useState(true);
  const [paymentRenovation, setPaymentRenovation] = useState(false);
  const [isWaitingForValidation, setIsWaitingForValidation] = useState(false);
  const [paymentWarning, setPaymentWarning] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isQuotesModalOpen, setIsQuotesModalOpen] = useState(false);
  const [payments, setPayments] = useState([]);
  const [isQuotesRegularization, setIsQuotesRegularization] = useState(false);
  const [showModalPaymentInfo, setShowModalPaymentInfo] = useState(false);

  const scrolledRef = useRef(false);
  const { hash } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      handleGetUserInfo();
    }
  }, [user]);

  function handleGetUserInfo() {
    setIsLoading(true);
    axios
      .get(endoinpts.purchase.getPurchases, {
        params: { id_user: user.id },
      })
      .then((res) => {
        let paymentsData = res.data[0];

        user.quotes_regularization === 0 ? setIsQuotesRegularization(true) : setIsQuotesRegularization(false);

        if (user.payment_valid) {
          setIsWaitingForValidation(false);
          setIsPaymentNeeded(false);
        } else {
          if (paymentsData.length > 0) {
            if (paymentsData[0].status === "em validação" || paymentsData[0].status === "próximo pagamento") {
              setIsWaitingForValidation(true);
              setIsPaymentNeeded(false);
            } else if (paymentsData[0].status === "cancelado") {
              setIsWaitingForValidation(false);
              setIsPaymentNeeded(true);
            } else {
              setIsWaitingForValidation(false);
              setIsPaymentNeeded(true);
            }
          } else {
            setIsWaitingForValidation(false);
            setIsPaymentNeeded(true);
          }
        }

        setIsLoading(false);
        setPayments(paymentsData);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handlePayQuote() {
    setPaymentOptions(true);
  }

  function handleCloseQuotesRegularization() {
    setIsQuotesRegularization(false);
    handleGetUserInfo();
  }

  function handleClosePaymentSteps() {
    setPaymentOptions(false);
    handleGetUserInfo();
  }

  function handleUpdateUserInfo() {
    handleGetUserInfo();
  }

  function handleCloseQuotes() {
    setIsQuotesModalOpen(false);
    handleGetUserInfo();
  }

  return (
    <Row className="w-100 dashboard" ref={scrolledRef}>
      <Col span={24}>
        <Spin spinning={isLoading}>
          <ModalRegularization user={user} show={isQuotesRegularization} close={() => handleCloseQuotesRegularization()} />
          <PaymentSteps user={user} open={paymentOptions} close={handleClosePaymentSteps} />
          <QuotesModal show={isQuotesModalOpen} close={handleCloseQuotes} payments={payments} user={user} handleUpdateUserInfo={handleUpdateUserInfo} />
          <Col span={24}>
            <Row className="content">
              <ModalPaymentInfo show={showModalPaymentInfo} cancel={() => setShowModalPaymentInfo(false)} payment={payments[0]} />
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Row className="mt-20" gutter={24} align="stretch">
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Row className="dash_card">
                          <Col span={24}>
                            <Row className="ml-0 mr-0">
                              <p className="dash_title">Gestão de Quotas</p>
                            </Row>
                            <Row className="ml-0 mr-0">
                              <div className="mr-30">
                                {isPaymentNeeded && <img src={quotasRedSVG} alt="quotas" />}
                                {isWaitingForValidation && <img src={quotasYellowSVG} alt="quotas" />}
                                {!isPaymentNeeded && !isWaitingForValidation && <img src={quotasSVG} alt="quotas" />}
                              </div>
                              <div>
                                <p className="grey m-0 mb-5 bold">
                                  {isWaitingForValidation && "À espera"}
                                  {isPaymentNeeded && "Em falta"}
                                  {!isPaymentNeeded && !isWaitingForValidation && "Pago"}
                                </p>
                                <p className="f-16 m-0 light-grey">
                                  {isWaitingForValidation && !isPaymentNeeded && "À espera de pagamento"}
                                  {isPaymentNeeded && !isWaitingForValidation && "Pagamento em falta"}
                                  {!isPaymentNeeded && !isWaitingForValidation && moment(user.valid_access_date).format("DD-MM-YYYY")}
                                </p>
                                <div className="buttons_row mt-20">
                                  {payments.length > 0 && (
                                    <>{paymentRenovation && user.payment_method !== "debit" && <Button className="green_button mr-10 mt-10">Renovar</Button>}</>
                                  )}
                                  {isWaitingForValidation && (
                                    <div className="d-flex">
                                      <Button className="button-yellow mr-10 mt-10" onClick={() => setShowModalPaymentInfo(true)}>
                                        Ver dados
                                      </Button>
                                      <Button className="transparent_button_yellow mt-10" onClick={() => setIsQuotesModalOpen(true)}>
                                        As minhas quotas
                                      </Button>
                                    </div>
                                  )}
                                  {isPaymentNeeded && (
                                    <>
                                      <Button type="primary" danger onClick={handlePayQuote} className="mr-10 mt-10">
                                        Efetuar pagamento
                                      </Button>
                                      {payments.length > 0 && (
                                        <Button className={"transparent_button_red mt-10"} onClick={() => setIsQuotesModalOpen(true)}>
                                          As minhas quotas
                                        </Button>
                                      )}
                                    </>
                                  )}
                                  {!isPaymentNeeded && !isWaitingForValidation && payments.length > 0 && (
                                    <Button type="primary" onClick={() => setIsQuotesModalOpen(true)}>
                                      As minhas quotas
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="dash_card">
                          <Col span={24}>
                            <Row className="ml-0 mr-0">
                              <p className="f-20 grey mt-0 dash_title">Formulário Departamento Jurídico</p>
                            </Row>
                            <Row className="ml-0 mr-0">
                              <Col span={7}>
                                <img src={legalSVG} alt="quotas" />
                              </Col>
                              <Col span={17}>
                                <p className="light-grey mt-0">Caso pretenda usufruir deste serviço, deve colocar as suas questões através deste formulário.</p>
                                <div className="buttons_row mt-20">
                                  {user?.payment_valid ? (
                                    <Button className="grey_button mr-10" onClick={() => navigate(`/formulario`)}>
                                      Formulário
                                    </Button>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <DashboardEvents user={user} />
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <DashboardCertificates user={user} />
                      </Col>
                    </Row>
                    <Row gutter={24} className="ml-0 mr-0" id="eventos">
                      <Col span={24}>
                        <DashboardListEvents user={user} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Spin>
      </Col>
    </Row>
  );
}

export default Home;
