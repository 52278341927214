import React, { useState, useEffect } from "react";
import { Table, Drawer, Row, Col, Input, Button } from "antd";
import axios from "axios";
import moment from "moment";

import endpoints from "../../../../utils/endoinpts";
import userDefault from "../../../../assets/images/user-default.svg";
import "./Subscribers.css";
import DrawerExportTable from "../../Export/DrawerExportTable";
import config from "../../../../utils/config";
import { DeleteOutlined } from "@ant-design/icons";
import Cancel from "./Cancel";

const { Search } = Input;

/* table config */
const columns = [
  {
    title: "Sócio",
    dataIndex: "n_member",
    sorter: (a, b) => {
      let a_n_member = a.n_member.props.children
        ? Number(a.n_member.props.children)
        : null;
      let b_n_member = b.n_member.props.children
        ? Number(b.n_member.props.children)
        : null;
      return a_n_member - b_n_member;
    },
  },
  {
    title: "",
    dataIndex: "image",
    width: 10,
  },
  {
    title: "Nome",
    dataIndex: "name",
  },
  {
    title: "E-mail",
    dataIndex: "email",
  },
  {
    title: "Profissão",
    dataIndex: "profession",
    filters: [
      {
        text: "Médico(a) de Família",
        value: "Médico(a) de Família",
      },
      {
        text: "Médico(a) interno(a)",
        value: "Médico(a) interno(a)",
      },
      {
        text: "Enfermeiro(a)",
        value: "Enfermeiro(a)",
      },
      {
        text: "Secretário(a) Clínico(a)",
        value: "Secretário(a) Clínico(a)",
      },
      {
        text: "Outro Profissional de Saúde",
        value: "Outro Profissional de Saúde",
      },
    ],
    onFilter: (value, record) => record.profession_name === value,
  },
  {
    title: "ARS",
    dataIndex: "ars",
  },
  {
    title: "ACeS",
    dataIndex: "aces",
  },
  {
    title: "Unidade",
    dataIndex: "usf",
  },
  {
    title: "Data de inscrição",
    dataIndex: "enroll_date",
  },
  {
    title: "Método de pagamento",
    dataIndex: "payment_method",
  },
  {
    title: "Pagamento",
    dataIndex: "payment_status",
    filters: [
      {
        text: "Aguarda pagamento",
        value: "Aguarda pagamento",
      },
      {
        text: "Pago",
        value: "pago",
      },
    ],
    onFilter: (value, record) => record.payment_status.props.children === value,
    width: 200,
  },
  {
    title: "",
    dataIndex: "actions",
  },
];

export default function Subscribers({ open, close, event }) {
  const [dataUsers, setDataUsers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [dataToExport, setDataToExport] = useState([]);
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [isOpenCancel, setIsOpenCancel] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const expandedRowRender = (e) => {
    const columnsExpanded = [
      {
        title: "Item",
        dataIndex: "item",
        key: "item",
      },
      {
        title: "Qtd",
        dataIndex: "qtd",
        key: "qtd",
      },
      {
        title: "Preço",
        dataIndex: "price",
        key: "price",
      },
    ];

    const dataExpanded = [];
    let aux = JSON.parse(e.invoice_items);

    for (let z = 0; z < aux.length; z++) {
      let description = aux[z].description.split(`${event.name} - `)[1];
      dataExpanded.push({
        key: e.key.toString() + z.toString(),
        item: description,
        qtd: aux[z].quantity,
        price: `${aux[z].price}€`,
      });
    }
    return (
      <Table
        className="expanded_table"
        columns={columnsExpanded}
        dataSource={dataExpanded}
        pagination={false}
      />
    );
  };

  useEffect(() => {
    if (Object.keys(event).length > 0 && open) {
      handleGetDataEvent();
    }
  }, [event]);

  function handleGetDataEvent() {
    axios
      .get(endpoints.event.readUsersByEvent, {
        params: { id_event: event.id },
      })
      .then((res) => {
        setDataUsers(res.data);
        handlePrepareData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handlePrepareData(array) {
    let newArrayItens = [];
    array.map((item, index) => {
      newArrayItens.push({
        key: item.id,
        id: item.id,
        n_member: item.id_user ? (
          <div className="n_member_table">{item.n_member}</div>
        ) : (
          "-"
        ),
        image: (
          <div className="user_img">
            <div
              className="img"
              style={{
                backgroundImage: item.user_img
                  ? `url(${config.server_ip}media/${item.user_img})`
                  : `url(${userDefault})`,
              }}
            ></div>
          </div>
        ),
        name: item.id_user ? item.user_name : item.guest_name,
        profession: item.profession_name
          ? item.profession_name
          : item.guest_profession_name,
        ars: item.id_user ? item.user_ars_name : item.guest_ars_name,
        aces: item.id_user ? item.user_aces_name : item.guest_aces_name,
        usf: item.id_user ? item.user_usf_name : item.guest_usf_name,
        email: item.user_email ? item.user_email : item.guest_email,
        enroll_date: moment(item.created_at).format("DD-MM-YYYY HH:mm"),
        payment_method: item.method === "mb" ? "Multibanco" : "MBWAY",
        payment_status: handlePaymentStatus(item.status),
        actions:
          item.status !== "pago" && item.status !== "cancelado" ? (
            <Button danger onClick={() => handleOpenCancel(item)}>
              <DeleteOutlined />
            </Button>
          ) : null,
        invoice_items: item.invoice_items,
        full_data: item,
      });
    });

    setIsTableLoading(false);
    setTableData(newArrayItens);
  }

  function handlePaymentStatus(status) {
    if (status === "pago") {
      return <div className="events_payment payed">Pago</div>;
    } else if (status === "cancelado") {
      return <div className="events_payment canceled">Cancelado</div>;
    } else {
      return (
        <div className="events_payment waiting_payment">Aguarda pagamento</div>
      );
    }
  }

  function handleSearch(e) {
    let text = e.target.value;
    setIsTableLoading(true);
    setSearchText(text);
    const newData = dataUsers.filter((item) => {
      if (item.id_user) {
        return (
          item.user_name.toLowerCase().includes(text.toLowerCase()) ||
          item.user_email.toLowerCase().includes(text.toLowerCase())
        );
      } else {
        return (
          item.guest_name.toLowerCase().includes(text.toLowerCase()) ||
          item.guest_email.toLowerCase().includes(text.toLowerCase())
        );
      }
    });

    handlePrepareData(newData);
  }

  function handleCloseExport() {
    setIsOpenExport(false);
  }

  function handlePrepareDataToExport() {
    let aux = [];
    for (let i = 0; i < tableData.length; i++) {
      console.log(tableData[i].invoice_items);
      let invoice_items = JSON.parse(tableData[i].invoice_items);
      let objInvoiceItems = {};
      for (let z = 0; z < invoice_items.length; z++) {
        let auxObjInvoiceItems = {
          [`Item_${z + 1}`]: invoice_items[z].description.split(
            `${event.name} - `
          )[1],
          [`Item_${z + 1}_price`]: invoice_items[z].price,
          [`Item_${z + 1}_qtd`]: invoice_items[z].quantity,
          [`Item_${z + 1}_discount`]: invoice_items[z].discount,
        };
        objInvoiceItems = {
          ...objInvoiceItems,
          ...auxObjInvoiceItems,
        };
      }

      delete tableData[i].full_data;

      aux.push({
        ...tableData[i],
        ...objInvoiceItems,
      });
    }

    setDataToExport(aux);
    setIsOpenExport(true);
  }

  function handleCancelPayment() {
    console.log(selectedData);
    axios
      .post(endpoints.purchase.paymentUserCancel, {
        data: selectedData,
      })
      .then((res) => {
        setSelectedData({});
        setIsOpenCancel(false);
        handleGetDataEvent();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleOpenCancel(item) {
    setSelectedData(item);
    setIsOpenCancel(true);
  }

  function handleCloseCancel() {
    setIsOpenCancel(false);
    handleGetDataEvent();
  }

  return (
    <Drawer
      className="event_subscribers"
      title={event.name}
      width={1400}
      onClose={close}
      open={open}
      bodyStyle={{ paddingBottom: 80 }}
      maskClosable={false}
    >
      <Cancel
        open={isOpenCancel}
        close={handleCloseCancel}
        submit={handleCancelPayment}
      />
      <DrawerExportTable
        open={isOpenExport}
        close={handleCloseExport}
        data={dataToExport}
        type={"Subscritores do Evento"}
      />
      <Row gutter={[24]} className="mt-20">
        <Col span={12}>
          <p className="f-20 bold m-0">
            Inscritos no evento:{" "}
            {dataUsers.filter((item) => item.status === "pago").length}
          </p>
        </Col>
        <Col span={12} align="end">
          <Row>
            <Col span={8} align="end">
              <Button
                type="primary"
                size="large"
                className="mr-10"
                onClick={handlePrepareDataToExport}
              >
                Exportar Tabela
              </Button>
            </Col>
            <Col span={16}>
              <Search
                placeholder="Procurar..."
                allowClear
                size="large"
                onChange={handleSearch}
                style={{
                  width: "100%",
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        scroll={{ x: 2000 }}
        columns={columns}
        dataSource={tableData}
        loading={isTableLoading}
        expandable={{
          expandedRowRender,
        }}
      />
    </Drawer>
  );
}
