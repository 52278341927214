import { useState, useEffect } from 'react'
import { Col, Row, Button, Form, Input, Select, InputNumber, DatePicker, Radio, Drawer, notification } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import axios from 'axios';
import dayjs from 'dayjs';

import endpoints from '../../../../utils/endoinpts';
import payedAssoc from '../../../../assets/icons/Paying-Associates.svg';
import eventsCalendar from '../../../../assets/icons/Events-Calendar.svg';
import './Update.css';

const { TextArea } = Input


export default function Update({ user, voucher, open, close }) {

  const [professions, setProfessions] = useState([]);
  const [events, setEvents] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersFiltered, setUsersFiltered] = useState([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(voucher).length > 0) {
      handleGetData();
    }
  }, [voucher]);

  function handleGetData() {
    axios.get(endpoints.voucher.getInfoData).then(res => {
      voucher.date_limit = dayjs(voucher.date_limit);
      voucher.events = voucher.events ? JSON.parse(voucher.events) : [];
      voucher.users = voucher.users ? JSON.parse(voucher.users) : [];
      voucher.professions = voucher.professions ? JSON.parse(voucher.professions) : [];

      form.setFieldsValue(voucher);
      setEvents(res.data[0]);
      setProfessions(res.data[1]);
      setUsers(res.data[2]);
      setUsersFiltered(res.data[2]);
    }).catch((error) => {
      console.error(error);
    });
  }

  function handleSubmitVoucher(values) {
    setIsButtonLoading(true);

    let quantity_left = voucher.quantity_left;

    if (values.quantity !== null && voucher.quantity === null) {
      quantity_left = values.quantity;
    } else if (values.quantity === null && voucher.quantity !== null) {
      quantity_left = values.quantity;
    } else if (values.quantity !== null && voucher.quantity !== null && (Number(values.quantity) !== Number(voucher.quantity))) {
      if (values.quantity > voucher.quantity) {
        let diff = values.quantity - voucher.quantity;
        quantity_left = voucher.quantity_left + diff;
      } else {
        let diff = voucher.quantity - values.quantity;
        quantity_left = voucher.quantity_left - diff;
      }
    } else {
      quantity_left = voucher.quantity;
    }

    let voucherData = {
      id: voucher.id,
      name: values.name,
      type: values.type,
      date_limit: dayjs(values.date_limit).format('YYYY-MM-DD'),
      description: values.description,
      voucher_type: values.voucher_type,
      value: values.value,
      events: values.type === 'quotes' ? null : (values.events.length > 0 ? JSON.stringify(values.events) : null),
      professions: values.professions.length > 0 ? JSON.stringify(values.professions) : null,
      users: values.users.length > 0 ? JSON.stringify(values.users) : null,
      quantity: values.quantity ? values.quantity : null,
      quantity_left: quantity_left,
      quantity_user: values.quantity_user ? values.quantity_user : null
    }

    axios.post(endpoints.voucher.editVoucher, {
      data: voucherData
    }).then((res) => {
      setTimeout(() => {
        notification.success({
          message: 'Sucesso',
          description: 'O voucher foi editado com sucesso',
          placement: 'top',
        });
        close();
        setIsButtonLoading(false);
        form.setFieldsValue({});
      }, 1500)
    }).catch((error) => {
      setIsButtonLoading(false);
      console.log(error);
    });
  }

  function handleChangeProfessions(e) {
    if (e.length > 0) {
      let auxUsers = users.filter((item) => e.includes(item.id_profession));
      setUsersFiltered(auxUsers);
    } else {
      setUsersFiltered(users);
    }
  }

  return (
    <Drawer
      title={`Editar voucher`}
      width={1000}
      onClose={close}
      open={open}
      maskClosable={false}
    >
      <Row id='admin-edit-voucher' className='dash_card' gutter={24}>
        <Col span={24}>
          <Form
            form={form}
            id="form-create-voucher"
            onFinish={handleSubmitVoucher}
            className='form'
            autoComplete="off"
            layout="vertical"
            disabled={user.is_viewer}
          >
            <Row gutter={[24]}>
              <Col span={24}>
                <Form.Item
                  name='type'
                  label="Tipo de voucher"
                  rules={[
                    {
                      required: true,
                      message: 'Insira um tipo para este voucher'
                    }
                  ]}
                >
                  <Radio.Group className='voucher_type_images' optionType="button">
                    <Radio value="events" className='voucher_type_images_item'>
                      <div><img src={eventsCalendar} /></div>
                      <div>Eventos</div>
                    </Radio>
                    <Radio value="quotes" className='voucher_type_images_item'>
                      <div><img src={payedAssoc} /></div>
                      <div>Quotas</div>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name='name'
                  label="Código"
                  rules={[
                    {
                      required: true,
                      message: 'Insira um código para o seu voucher'
                    }
                  ]}
                >
                  <Input size='large' placeholder="Código do Voucher" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='date_limit'
                  label="Data limite"
                  rules={[
                    {
                      required: true,
                      message: 'Insira uma data limite para o seu voucher'
                    }
                  ]}
                >
                  <DatePicker size='large' placeholder="Data limite" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name='description'
                  label="Descrição"
                >
                  <TextArea size='large' placeholder="Descrição" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name='voucher_type'
                  label="Percentagem ou valor?"
                  initialValue={'Percentagem'}
                  rules={[
                    {
                      required: true,
                      message: 'Insira um tipo para este voucher'
                    }
                  ]}
                >
                  <Radio.Group>
                    <Radio value="Percentagem"> Percentagem </Radio>
                    <Radio value="Valor"> Valor </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  name='value'
                  label="Percentagem/Valor"
                  rules={[
                    {
                      required: true,
                      message: 'Insira um valor para este voucher'
                    }
                  ]}
                >
                  <InputNumber size='large' min={1} placeholder={'Insira o número relacionado a valor ou percentagem'} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
            >
              {({ getFieldValue }) =>
                (getFieldValue('type') === 'events') && (
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        name='events'
                        label="Evento"
                        tooltip={{
                          title: 'Se deixar este campo vazio, vai ser para todos os eventos',
                          icon: <InfoCircleOutlined />,
                        }}
                      >
                        <Select
                          mode="tags"
                          size='large'
                          showSearch
                          placeholder="Selecione"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={events.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          getPopupContainer={trigger => trigger.parentNode}

                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
            </Form.Item>

            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name='professions'
                  label="Profissão"
                  tooltip={{
                    title: 'Se deixar este campo vazio, vai ser para todos as profissões',
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Select
                    mode="tags"
                    size='large'
                    showSearch
                    placeholder="Selecione"
                    optionFilterProp="children"
                    onChange={handleChangeProfessions}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={professions.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    getPopupContainer={trigger => trigger.parentNode}

                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name='users'
                  label="Utilizadores"
                  tooltip={{
                    title: 'Se deixar este campo vazio, vai ser para todos os utilizadores',
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Select
                    mode="tags"
                    size='large'
                    showSearch
                    placeholder="Selecione"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={usersFiltered.map((item) => ({
                      value: item.id,
                      label: item.email,
                    }))}
                    getPopupContainer={trigger => trigger.parentNode}

                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name='quantity'
                  label="Quantidade"
                  tooltip={{
                    title: 'Se deixar este campo vazio, o número de vochers é ilimitado',
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <InputNumber size='large' min={1} placeholder='Insira uma quantidade' />
                </Form.Item>
                <Form.Item
                  hidden
                  name='quantity_left'
                  label="Quantidade"
                >
                  <InputNumber size='large' min={1} placeholder='Insira uma quantidade' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='quantity_user'
                  label="Quantidade por utilizador"
                  tooltip={{
                    title: 'Se deixar este campo vazio, o número de uso dos vochers é ilimitado',
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <InputNumber size='large' min={1} placeholder='Insira uma quantidade' />
                </Form.Item>
              </Col>
            </Row>

          </Form>
          {!user.is_viewer ?
            <Row className='ml-0 mr-0 mt-20'>
              <Col span={24} align='center'>
                <Button loading={isButtonLoading} className='submit_button' onClick={form.submit}>
                  Guardar
                </Button>
              </Col>
            </Row>
            :
            null
          }
        </Col>
      </Row>
    </Drawer>
  )
}