import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Spin,
  Form,
  notification,
  Input,
  Divider,
  DatePicker,
  Select,
  Radio,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";

import addPhoto from "../../../../assets/icons/Add-a-photo.svg";
import endpoints from "../../../../utils/endoinpts";
import config from "../../../../utils/config";
import geoAPI from "../../../../utils/districts";
import "./Update.css";

const dateFormat = "YYYY/MM/DD";

export default function PersonalData({ admin, activeKey, user_id, close }) {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [ars, setArs] = useState([]);
  const [aces, setAces] = useState([]);
  const [acesFiltered, setACESFiltered] = useState([]);
  const [usf, setUsf] = useState([]);
  const [usfFiltered, setUSFFiltered] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [districts, setDistricts] = useState(geoAPI.districts);
  const [counties, setCounties] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (user_id && activeKey === 1) {
      handleGetUserData();
    }
  }, [activeKey, user_id]);

  function handleGetUserData() {
    setIsLoading(true);
    axios
      .get(endpoints.app.getUserAdmin, {
        params: { id: user_id },
      })
      .then((res) => {
        console.log(res);
        let userServer = res.data[0][0];
        userServer.birth_date = dayjs(userServer.birth_date);
        let acesServer = res.data[2];
        let usfServer = res.data[3];
        let acesFilter = acesServer.filter(
          (value, index) => value.id_ars === userServer.id_ars
        );
        let usfFilter = usfServer.filter(
          (value, index) => value.id_aces === userServer.id_aces
        );
        setUserData(res.data[0][0]);
        setArs(res.data[1]);
        setAces(acesServer);
        setACESFiltered(acesFilter);
        setUsf(usfServer);
        setUSFFiltered(usfFilter);
        setProfessions(res.data[4]);
        setRoles(res.data[5]);

        let district = null;
        let county = null;
        let parish = null;

        if (res.data[0][0].district) {
          district = geoAPI.districts.filter(
            (item) => item.name === res.data[0][0].district
          );
          if (district.length > 0) {
            handleChangeDistrict(res.data[0][0].district);
          } else {
            res.data[0][0].district = null;
          }
        }

        if (res.data[0][0].county && district.length > 0) {
          parish = district[0].county.filter(
            (item) => item.name === res.data[0][0].county
          );
          if (parish.length > 0) {
            handleChangeCounty(res.data[0][0].county);
          } else {
            res.data[0][0].county = null;
            res.data[0][0].parish = null;
          }
        }

        setIsLoading(false);
        form.setFieldsValue(userServer);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleChangeDistrict(e) {
    form.setFieldValue("county", null);
    form.setFieldValue("parish", null);
    let auxCounties = geoAPI.districts.filter((item) => item.name === e)[0];
    if (auxCounties) {
      setCounties(auxCounties.county);
    }
  }

  function handleChangeCounty(e) {
    form.setFieldValue("parish", null);
    let auxParishes = geoAPI.parishes.filter((item) => item.nome === e)[0];
    if (auxParishes) {
      setParishes(auxParishes.freguesias);
    }
  }

  function handleChangeSelectARS(value) {
    form.setFieldsValue({ id_aces: null });
    form.setFieldsValue({ id_usf: null });
    let aux = [];
    for (let i = 0; i < aces.length; i++) {
      if (aces[i].id_ars === value) {
        aux.push(aces[i]);
      }
    }

    setUSFFiltered([]);
    setACESFiltered(aux);
  }

  function handleChangeSelectACES(value) {
    form.setFieldsValue({ usf: null });
    let aux = [];
    for (let i = 0; i < usf.length; i++) {
      if (usf[i].id_aces === value) {
        aux.push(usf[i]);
      }
    }

    setUSFFiltered(aux);
  }

  function handleChangeProfession(e) {
    let profession_name = professions.filter(
      (value, index) => value.id === e
    )[0];
    form.setFieldValue("profession_name", profession_name.name);
  }

  function handleSubmit(values) {
    setIsSaving(true);
    let formData = new FormData();

    formData.append("data", JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.app.editUser}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        setTimeout(() => {
          setIsSaving(false);
          notification.success({
            message: "Dados gravados",
            description: "Os dados do seu perfil foram gravados com sucesso.",
            placement: "top",
          });
          close();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message: "Erro",
          description:
            "Aconteceu algum erro, por favor tente novamente mais tarde.",
          placement: "top",
        });
        setIsSaving(false);
      });
  }

  function handleGenerateNewPassword() {
    setIsSaving(true);
    axios
      .post(endpoints.app.generateNewPassword, {
        data: userData,
      })
      .then((res) => {
        setIsSaving(false);
        notification.success({
          message: "Dados gravados",
          description: "Os dados do seu perfil foram gravados com sucesso.",
        });
        close();
      })
      .catch((err) => {
        console.log(err);
        setIsSaving(false);
        notification.error({
          message: "Dados não foram gravados",
          description: "Os dados do seu perfil foram gravados com sucesso.",
        });
        close();
      });
  }

  return (
    <Spin spinning={isLoading}>
      <Row className="edit_profile_content drawer">
        <Col span={24}>
          <Form
            form={form}
            id="edit-profile"
            layout="vertical"
            onFinish={handleSubmit}
            disabled={admin.is_viewer}
          >
            <Row className="img_data">
              <Col span={24}></Col>
            </Row>
            <Row className="personal_data">
              <Col span={24}>
                <p className="edit_profile_title mt-0">Dados Pessoais</p>
                <Divider className="edit_profile_divider" />
                <Row gutter={24}>
                  <Col span={8}>
                    <div className="edit_profile_pic admin">
                      <div className="upload_content">
                        <div
                          className="pic"
                          style={{
                            backgroundImage: userData.img
                              ? `url(${config.server_ip}media/${userData.img})`
                              : `url(${addPhoto})`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </Col>
                  <Col span={16}>
                    <Row gutter={24}>
                      <Col span={12}>
                        <Form.Item hidden name="id">
                          <Input />
                        </Form.Item>
                        <Form.Item hidden name="img">
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="name"
                          label="Name"
                          rules={[
                            {
                              required: true,
                              message: "Este campo é obrigatório",
                            },
                          ]}
                        >
                          <Input type="text" size="large" placeholder="Name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="birth_date" label="Data de nascimento">
                          <DatePicker format={dateFormat} size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={12}>
                        <Form.Item name="phone" label="Telefone">
                          <Input
                            type="text"
                            size="large"
                            placeholder="Telefone"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="cc" label="BI/CC">
                          <Input
                            type="text"
                            size="large"
                            placeholder="Telefone"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={24}>
                        <Form.Item
                          name="n_member"
                          label="Nº Sócio"
                          className="mb-0"
                        >
                          <Input
                            type="text"
                            size="large"
                            placeholder="Nº Sócio"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <p className="edit_profile_title">Morada</p>
                <Divider className="edit_profile_divider" />
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item name="address" label="Morada (residência)">
                      <Input
                        type="text"
                        size="large"
                        placeholder="Morada (residência)"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item name="post_code" label="Código-Postal">
                      <Input
                        type="text"
                        size="large"
                        placeholder="Código-Postal"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="district" label="Distrito">
                      <Select
                        size="large"
                        showSearch
                        placeholder="Distrito"
                        optionFilterProp="children"
                        autoComplete="none"
                        onChange={handleChangeDistrict}
                        filterOption={(input, option) =>
                          (option?.children ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                        options={districts.map((item) => ({
                          value: item.name,
                          label: item.name,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="county" label="Concelho">
                      <Select
                        size="large"
                        showSearch
                        placeholder="Concelho"
                        optionFilterProp="children"
                        autoComplete="none"
                        onChange={handleChangeCounty}
                        filterOption={(input, option) =>
                          (option?.children ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                        options={counties.map((item) => ({
                          value: item.name,
                          label: item.name,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="parish" label="Localidade">
                      <Select
                        size="large"
                        showSearch
                        placeholder="Localidade"
                        optionFilterProp="children"
                        autoComplete="none"
                        filterOption={(input, option) =>
                          (option?.children ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                        options={parishes.map((item) => ({
                          value: item,
                          label: item,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <p className="edit_profile_title">Dados Profissionais</p>
                <Divider className="edit_profile_divider" />
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item
                      name="professional_name"
                      label="Nome profissional"
                    >
                      <Input
                        type="text"
                        size="large"
                        placeholder="Nome profissional"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="id_profession" label="Profissão">
                      <Select
                        size="large"
                        showSearch
                        placeholder="Profissão"
                        optionFilterProp="children"
                        autoComplete="none"
                        onChange={handleChangeProfession}
                        filterOption={(input, option) =>
                          (option?.children ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {professions.map((value, index) => {
                          return (
                            <Select.Option key={value.id} value={value.id}>
                              {value.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="id_role" label="Cargo na unidade">
                      <Select
                        size="large"
                        showSearch
                        placeholder="Cargo na unidade"
                        optionFilterProp="children"
                        autoComplete="none"
                        onChange={handleChangeProfession}
                        filterOption={(input, option) =>
                          (option?.children ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {roles.map((value, index) => {
                          return (
                            <Select.Option key={value.id} value={value.id}>
                              {value.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item
                      name="retiree"
                      label="Está neste momento aposentado?"
                    >
                      <Radio.Group>
                        <Radio value={1}>Sim</Radio>
                        <Radio value={0}>Não</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="id_ars" label="ARS">
                      <Select
                        size="large"
                        showSearch
                        placeholder="ARS"
                        optionFilterProp="children"
                        onChange={handleChangeSelectARS}
                        filterOption={(input, option) =>
                          (option?.children ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {ars.map((value, index) => {
                          return (
                            <Select.Option key={value.id} value={value.id}>
                              {value.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="id_aces" label="ACeS">
                      <Select
                        size="large"
                        showSearch
                        placeholder="ACeS"
                        optionFilterProp="children"
                        onChange={handleChangeSelectACES}
                        filterOption={(input, option) =>
                          (option?.children ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {acesFiltered.map((value, index) => {
                          return (
                            <Select.Option key={value.id} value={value.id}>
                              {value.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item name="id_usf" label="Unidade">
                      <Select
                        size="large"
                        showSearch
                        placeholder="Unidade"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.children ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {usfFiltered.map((value, index) => {
                          return (
                            <Select.Option key={value.id} value={value.id}>
                              {value.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="work_district" label="Distrito">
                      <Select
                        size="large"
                        showSearch
                        placeholder="Distrito"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.children ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {geoAPI.districts.map((value, index) => {
                          return (
                            <Select.Option
                              key={value.value}
                              value={value.value}
                            >
                              {value.label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} className="access_row">
                  <Col span={24}>
                    <p className="edit_profile_title mt-0">Acessos</p>
                    <Divider className="edit_profile_divider" />
                    <Row gutter={24}>
                      <Col span={16}>
                        <Form.Item
                          name="email"
                          label="Email"
                          rules={[
                            {
                              required: true,
                              message: "Este campo é obrigatório",
                            },
                          ]}
                        >
                          <Input type="text" size="large" placeholder="Email" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Button
                          loading={isSaving}
                          size="large"
                          className="w-100 mt-30"
                          onClick={handleGenerateNewPassword}
                        >
                          Gerar nova password
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={24} className="access_row">
                  <Col span={24}>
                    <p className="edit_profile_title mt-0">
                      Dados de pagamento
                    </p>
                    <Divider className="edit_profile_divider" />
                    <Row gutter={24}>
                      <Col span={12}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.payment_method !==
                            currentValues.payment_method
                          }
                        >
                          {({ getFieldValue }) => (
                            <Form.Item
                              name="payment_method"
                              label="Método de pagamento"
                              className="mb-0"
                            >
                              <Select
                                size="large"
                                placeholder="Métodio de pagamento"
                                disabled
                              >
                                <Select.Option
                                  key={getFieldValue("payment_method")}
                                  value={getFieldValue("payment_method")}
                                >
                                  {getFieldValue("payment_method")
                                    ? getFieldValue("payment_method") === "mb"
                                      ? "Multibanco"
                                      : getFieldValue("payment_method") ===
                                        "mbway"
                                      ? "MBWay"
                                      : "Débito direto"
                                    : "Sem pagamento associado"}
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="nif"
                          label="NIF"
                          rules={[
                            {
                              required: true,
                              message: "Este campo é obrigatório",
                            },
                          ]}
                        >
                          <Input type="text" size="large" placeholder="NIF" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.payment_method !==
                        currentValues.payment_method
                      }
                    >
                      {({ getFieldValue }) => {
                        if (getFieldValue("payment_method") === "debit") {
                          return (
                            <>
                              <Row gutter={24}>
                                <Col span={16}>
                                  <Form.Item name="iban" label="IBAN">
                                    <Input
                                      type="text"
                                      size="large"
                                      placeholder="IBAN"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={24}>
                                <Col span={12}>
                                  <Form.Item
                                    name="bank_name"
                                    label="Nome do banco"
                                  >
                                    <Input
                                      type="text"
                                      size="large"
                                      placeholder="Nome do banco"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name="bank_user_name"
                                    label="Nome do/a Associado/a"
                                  >
                                    <Input
                                      type="text"
                                      size="large"
                                      placeholder="Nome do/a Associado/a"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          );
                        }
                      }}
                    </Form.Item>
                  </Col>
                </Row>
                {admin.is_admin ? (
                  <Row gutter={24} className="buttons_edit_profile">
                    <Col
                      span={12}
                      className="d-flex flex-column ai-end jc-center"
                    >
                      <Button className="minw-200" size="large" onClick={close}>
                        Voltar
                      </Button>
                    </Col>
                    <Col
                      span={12}
                      className="d-flex flex-column ai-start jc-center"
                    >
                      <Button
                        size="large"
                        type="primary"
                        loading={isSaving}
                        className="minw-200"
                        onClick={form.submit}
                      >
                        Guardar alterações
                      </Button>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
}
