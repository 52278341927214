import { useEffect, useState } from "react";
import { Col, Modal, Row, Button, Spin, Form, Checkbox } from "antd";
import axios from "axios";

import endpoints from "../../../utils/endoinpts";
import "./ModalCancelDebit.css";
import config from "../../../utils/config";

function ModalCancelDebit({ user, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleSubmit() {
    setIsButtonLoading(true);
    axios
      .get(endpoints.payment.getUserPayments, {
        params: { id: user.id },
      })
      .then((res) => {
        let auxData = res.data;
        console.log(auxData);
        if (auxData.length > 0) {
          axios
            .post(endpoints.payment.cancelDebit, {
              data: { user: user, payment: auxData[0] },
            })
            .then((res) => {
              close();
              setIsButtonLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setIsButtonLoading(false);
            });
        } else {
          setIsButtonLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
      });
  }

  return (
    <>
      <Modal
        title="Cancelamento do débito direto"
        className="modal_cancel"
        open={open}
        onCancel={close}
        footer={[
          <div className="quotes_regularization_footer">
            <Button size="large" onClick={close} disabled={isButtonLoading}>
              Não
            </Button>
            <Button
              danger
              size="large"
              onClick={handleSubmit}
              loading={isButtonLoading}
            >
              Sim
            </Button>
          </div>,
        ]}
      >
        <div className="d-flex flex-column">
          <p>Tem a certeza que quer cancelar o seu débito direto?</p>
        </div>
      </Modal>
    </>
  );
}

export default ModalCancelDebit;
