import { useState, useEffect, useRef } from "react";
import { Col, Row, Upload, Form, Input, Select, Spin, Button, Checkbox, Table, Drawer, notification, Divider } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import ReactQuill from "react-quill";
import endpoints from "../../../../utils/endoinpts";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";

const { Dragger } = Upload;
const { TextArea } = Input;

export default function Recipients({ form, data, open, close }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [ars, setArs] = useState([]);
  const [aces, setAces] = useState([]);
  const [usf, setUsf] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventsUsers, setEventsUsers] = useState([]);
  const columns = [
    {
      title: "",
      dataIndex: "image",
    },
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "E-mail",
      dataIndex: "email",
    },
    {
      title: "Profession",
      dataIndex: "profession_name",
    },
  ];

  useEffect(() => {
    console.log("enter OPEN");
    if (open) {
      handleGetData();
    }
  }, [open]);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.communications.readInfo)
      .then((res) => {
        let finalData = res.data[0].map((item) => ({ ...item, key: item.id }));
        setUsers(finalData);
        setSelectedRowKeys(data.map((item) => item.id));
        setFilteredUsers(finalData);
        setProfessions(res.data[1]);
        setArs(res.data[2]);
        setAces(res.data[3]);
        setUsf(res.data[4]);
        setEvents(res.data[5]);
        setEventsUsers(res.data[6]);

        let allValues = form.getFieldsValue();
        console.log(allValues);
        if (Object.values(allValues).filter((item) => item !== undefined).length > 0) {
          handleChangeValues(null, allValues);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handleClose() {
    let recipientsUsers = [];
    if (selectedRowKeys.length > 0) {
      recipientsUsers = users.filter((item) => selectedRowKeys.includes(item.id));
    }
    close(recipientsUsers);
  }

  function handleChangeValues(_, allValues) {
    let tableData = JSON.parse(JSON.stringify(users));
    let formProfessions = allValues.professions;
    let formARS = allValues.ars;
    let formACES = allValues.aces;
    let formUSF = allValues.usf;
    let formQuotes = allValues.quotes;
    let formSearch = allValues.search;
    let formEvents = allValues.events;
    let formEventPaymentStatus = allValues.events_payment_status;

    if (formProfessions && formProfessions.length > 0) {
      tableData = tableData.filter((item) => formProfessions.includes(item.id_profession));
    }

    if (formARS && formARS.length > 0) {
      tableData = tableData.filter((item) => formARS.includes(item.id_ars));
    }

    if (formACES && formACES.length > 0) {
      tableData = tableData.filter((item) => formACES.includes(item.id_aces));
    }

    if (formUSF && formUSF.length > 0) {
      tableData = tableData.filter((item) => formUSF.includes(item.id_usf));
    }

    if (formEvents && formEvents.length > 0) {
      let auxEventsUsers = eventsUsers.filter((item) => item.id_user && formEvents.includes(item.id_event));
      if (auxEventsUsers && auxEventsUsers.length > 0) {
        if (formEventPaymentStatus && formEventPaymentStatus.length > 0) {
          auxEventsUsers = auxEventsUsers.filter((item) => formEventPaymentStatus.includes(item.status));
        }
        let aux = auxEventsUsers.map((item) => item.id_user);
        tableData = tableData.filter((item) => aux.includes(item.id));
      }
    }

    if (formQuotes && formQuotes.length > 0) {
      let auxTableData = JSON.parse(JSON.stringify(tableData));
      let auxPayed = [];
      let auxActive = [];
      let auxInactive = [];
      if (formQuotes.includes("payed")) {
        auxPayed = auxTableData.filter((item) => item.payment_valid === 1);
      }

      if (formQuotes.includes("active")) {
        auxActive = auxTableData.filter((item) => {
          let valid_access_date = dayjs(item.valid_access_date);
          let today = dayjs();
          let yearsAccess = today.diff(valid_access_date, "year");
          return item.payment_valid === 0 && yearsAccess < 1;
        });
      }

      if (formQuotes.includes("inactive")) {
        auxInactive = auxTableData.filter((item) => {
          let valid_access_date = dayjs(item.valid_access_date);
          let today = dayjs();
          let yearsAccess = today.diff(valid_access_date, "year");
          return item.payment_valid === 0 && yearsAccess >= 1;
        });
      }

      tableData = [...auxPayed, ...auxActive, ...auxInactive];
    }

    if (formSearch && formSearch.length > 0) {
      let searchString = formSearch.toLowerCase();
      tableData = tableData.filter((item) => item.name.toLowerCase().includes(searchString) || item.email.toLowerCase().includes(searchString));
    }

    let finalData = tableData.map((item) => ({ ...item, key: item.id }));

    setFilteredUsers(finalData);
    setIsLoading(false);
  }

  const handleSelect = (record, selected) => {
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record.key]);
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record.key);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
    }
  };

  const toggleSelectAll = () => {
    setSelectedRowKeys((keys) => (keys.length === filteredUsers.length ? [] : filteredUsers.map((r) => r.key)));
  };

  const headerCheckbox = (
    <Checkbox checked={selectedRowKeys.length} indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < filteredUsers.length} onChange={toggleSelectAll} />
  );

  const rowSelection = {
    selectedRowKeys,
    type: "checkbox",
    fixed: true,
    onSelect: handleSelect,
    columnTitle: headerCheckbox,
  };

  function handleClean() {
    form.resetFields();
    setSelectedRowKeys([]);
    setFilteredUsers(users);
  }

  return (
    <Drawer
      title={`Destinatários`}
      width={1000}
      onClose={handleClose}
      open={open}
      maskClosable={false}
      extra={[
        <Button className="mr-10" size="large" onClick={handleClean}>
          Limpar
        </Button>,
        <Button type="primary" size="large" onClick={form.submit}>
          Adicionar destinatários
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        <Form form={form} id="form-create-voucher" onValuesChange={handleChangeValues} onFinish={handleClose} className="form" autoComplete="off" layout="vertical">
          <Row gutter={[24]}>
            <Col span={8}>
              <Form.Item name="ars" label="ARS">
                <Select
                  mode="multiple"
                  size="large"
                  showSearch
                  placeholder="ARS"
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  options={ars.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="aces" label="ACeS">
                <Select
                  mode="multiple"
                  size="large"
                  showSearch
                  placeholder="ACeS"
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  options={aces.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="usf" label="Unidade">
                <Select
                  mode="multiple"
                  size="large"
                  showSearch
                  placeholder="USF"
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  options={usf.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="professions" label="Profissão">
                <Select
                  mode="multiple"
                  size="large"
                  showSearch
                  placeholder="Profissão"
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  options={professions.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="quotes" label="Quotas">
                <Select
                  mode="multiple"
                  size="large"
                  placeholder="Quotas"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  options={[
                    {
                      value: "payed",
                      label: "Pago",
                    },
                    {
                      value: "active",
                      label: "Não pago - ativos",
                    },
                    {
                      value: "inactive",
                      label: "Não pago - inativos",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="search" label="Procurar">
                <Input size="large" placeholder="Procurar" />
              </Form.Item>
            </Col>
            <Divider />
            <Col span={12}>
              <Form.Item name="events" label="Eventos">
                <Select
                  mode="multiple"
                  size="large"
                  showSearch
                  placeholder="Eventos"
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  options={events.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.events !== currentValues.events}>
                {({ getFieldValue }) => (
                  <Form.Item name="events_payment_status" label="Estado de pagamento">
                    <Select
                      mode="multiple"
                      size="large"
                      showSearch
                      placeholder="Eventos"
                      filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      options={
                        getFieldValue("events") && getFieldValue("events").length > 0
                          ? [
                              {
                                value: "pago",
                                label: "Pago",
                              },
                              {
                                value: "em validação",
                                label: "Não pago",
                              },
                            ]
                          : []
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Table rowSelection={rowSelection} columns={columns} dataSource={filteredUsers} />
      </Spin>
    </Drawer>
  );
}
