import { useEffect, useState } from "react";
import { Col, Row, Input, Table, Button, Space, DatePicker } from "antd";
import axios from "axios";
import moment from "moment";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  ReloadOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import userDefault from "../../../../assets/images/user-default.svg";
import endpoints from "../../../../utils/endoinpts";
import "./List.css";
import Import from "../Import/Import";
import Update from "../Update/Update";
import Delete from "../Delete/Delete";
import Activate from "../Activate/Activate";
import DrawerExportTable from "../../Export/DrawerExportTable";
import config from "../../../../utils/config";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const columnsTable = [
  {
    title: "Sócio",
    dataIndex: "n_member",
    sorter: (a, b) => {
      let a_n_member = a.n_member.props.children
        ? Number(a.n_member.props.children)
        : null;
      let b_n_member = b.n_member.props.children
        ? Number(b.n_member.props.children)
        : null;
      return a_n_member - b_n_member;
    },
  },
  {
    title: "",
    dataIndex: "img",
    width: "5%",
  },
  {
    title: "Nome",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "E-mail",
    dataIndex: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: "Profissão",
    dataIndex: "profession_name",
    filters: [
      {
        text: "Médico(a) de Família",
        value: "Médico(a) de Família",
      },
      {
        text: "Médico(a) interno(a)",
        value: "Médico(a)interno(a)",
      },
      {
        text: "Enfermeiro(a)",
        value: "Enfermeiro(a)",
      },
      {
        text: "Secretário(a) Clínico(a)",
        value: "Secretário(a) Clínico(a)",
      },
      {
        text: "Outro Profissional de Saúde",
        value: "Outro Profissional de Saúde",
      },
    ],
    onFilter: (value, record) => record.profession_name === value,
  },
  {
    title: "Unidade",
    dataIndex: "usf_name",
  },
  {
    title: "Tipo",
    dataIndex: "payment_plan",
    filters: [
      {
        text: "Anual",
        value: "Anual",
      },
      {
        text: "Mensal",
        value: "Mensal",
      },
    ],
    onFilter: (value, record) => record.payment_plan === value,
  },
  {
    title: "Último pagamento",
    dataIndex: "payment_date",
    sorter: (a, b) =>
      dayjs(a.payment_date).unix() - dayjs(b.payment_date).unix(),
  },
  {
    title: "Quotas",
    dataIndex: "quotes",
    filters: [
      {
        text: "Pago",
        value: "Pago",
      },
      {
        text: "Não pago",
        value: "Não pago",
      },
      {
        text: "Em validação",
        value: "Em validação",
      },
    ],
    onFilter: (value, record) => record.quotes.props.children === value,
    width: 220,
  },
  {
    title: "Membro desde",
    dataIndex: "member_since",
    sorter: (a, b) =>
      dayjs(a.member_since).unix() - dayjs(b.member_since).unix(),
  },
  {
    title: "Ativo",
    dataIndex: "active",
    filters: [
      {
        text: "Ativo",
        value: "0",
      },
      {
        text: "Apagado",
        value: "1",
      },
    ],
    onFilter: (value, record) => record.is_deleted === value,
  },
  {
    title: "",
    dataIndex: "actions",
  },
];

export default function List({ user }) {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenImportDrawer, setIsOpenImportDrawer] = useState(false);
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenActivate, setIsOpenActivate] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [searchText, setSearchText] = useState("");
  const [users, setUsers] = useState([]);
  const [dates, setDates] = useState(null);

  useEffect(() => {
    handleGetAdminInfo();
  }, []);

  function handleGetAdminInfo() {
    setIsLoading(true);
    axios
      .get(endpoints.info.getUsersDashboard)
      .then((res) => {
        let auxDash = res.data;
        let users = auxDash[0];
        setUsers(users);

        if (searchText.length > 0 || dates) {
          handleSearchUsers(searchText, dates);
        } else {
          handlePrepareTableData(users);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handlePrepareTableData(users) {
    let auxTable = [];

    for (let i = 0; i < users.length; i++) {
      let valid_access_date = dayjs(users[i].valid_access_date);
      let today = dayjs();
      let yearsAccess = today.diff(valid_access_date, "year");
      console.log(yearsAccess);
      let user_status = users[i].payment_valid
        ? "Pleno"
        : yearsAccess < 1
        ? "Ativo"
        : "Inativo";
      let auxData = users[i];
      auxData.status = user_status;
      let aux = {
        key: auxData.id,
        id: auxData.id,
        img: auxData.img ? (
          <div className="user_img">
            <div
              className="img"
              style={{
                backgroundImage: `url(${config.server_ip}media/${auxData.img})`,
              }}
            ></div>
          </div>
        ) : (
          <div className="img_default">
            <img src={userDefault} />
          </div>
        ),
        name: auxData.name,
        nif: auxData.nif,
        cc: auxData.cc,
        email: auxData.email,
        profession_name: auxData.profession_name,
        usf_name: auxData.usf_name,
        payment_plan: auxData.payment_plan_type,
        payment_date: auxData.payment_date
          ? moment(auxData.payment_date).format("YYYY-MM-DD")
          : "N/A",
        n_member: <div className="n_member_table">{auxData.n_member}</div>,
        member_since: auxData.member_since
          ? dayjs(auxData.member_since).format("YYYY-MM-DD")
          : null,
        quotes: handleCalcQuote(auxData),
        actions: user.is_admin ? (
          <div className="d-flex">
            <EditOutlined
              className="icon icon_edit"
              onClick={() => handleOpenDrawer(auxData)}
            />
            {auxData.is_deleted ? (
              <CheckCircleOutlined
                className="icon icon_edit ml-10"
                onClick={() => handleOpenActivate(auxData)}
              />
            ) : (
              <DeleteOutlined
                className="icon icon_edit ml-10"
                onClick={() => handleOpenDelete(auxData)}
              />
            )}
          </div>
        ) : (
          <EyeOutlined
            className="icon icon_edit"
            onClick={() => handleOpenDrawer(auxData)}
          />
        ),
        active: auxData.is_deleted ? (
          <CloseCircleOutlined className="red" />
        ) : (
          <CheckCircleOutlined className="green" />
        ),
        status: user_status,
        enabled: !auxData.is_deleted,
        is_deleted: auxData.is_deleted ? "1" : "0",
        full_data: auxData,
      };

      auxTable.push(aux);
    }

    setTableData(auxTable);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  function handleCalcQuote(data) {
    let valid_access_date = moment(data.valid_access_date);
    let today = moment();
    let durationAccesss = moment.duration(today.diff(valid_access_date));

    if (data.payment_method === null && !data.payment_valid) {
      return <div className="quotes not_payed">Sem pagamento</div>;
    } else {
      if (data.valid_access_date !== null) {
        if (durationAccesss < 0) {
          return <div className="quotes payed">Pago</div>;
        } else {
          return <div className="quotes not_payed">Não pago</div>;
        }
      } else {
        return <div className="quotes waiting_payment">Em validação</div>;
      }
    }
  }

  function handleSearchUsers(e, rangeDate) {
    setIsLoading(true);
    let searchString = typeof e === "string" ? searchText : e.target.value;
    let searchStringSplited = searchString.toLowerCase().split(" ");
    setSearchText(searchString);
    let auxDates = rangeDate;
    let usersFiltered = JSON.parse(JSON.stringify(users));

    if (auxDates && auxDates[0] && auxDates[1]) {
      let auxUsersFiltered = users.filter((value, index) => {
        return (
          dayjs(auxDates[0]).diff(value.payment_date, "day") <= 0 &&
          dayjs(auxDates[1]).diff(value.payment_date, "day") >= 0
        );
      });

      usersFiltered = auxUsersFiltered.filter((value, index) => {
        let firstName = value.name.toLowerCase().split(" ")[0];
        let lastName = value.name.toLowerCase().split(" ")[
          value.name.split(" ").length - 1
        ];
        let usfName = value.usf_name ? value.usf_name : "";
        return (
          value.nif?.toString().includes(searchString) ||
          value.cc?.toString().includes(searchString) ||
          value.name.includes(searchString) ||
          value.n_member?.toString().includes(searchString) ||
          value.email.toString().includes(searchString) ||
          usfName.includes(searchString) ||
          (firstName.includes(searchStringSplited[0]) &&
            lastName.includes(searchStringSplited[1]))
        );
      });
    } else {
      usersFiltered = users.filter((value, index) => {
        let firstName = value.name.toLowerCase().split(" ")[0];
        let lastName = value.name.toLowerCase().split(" ")[
          value.name.split(" ").length - 1
        ];
        let usfName = value.usf_name ? value.usf_name : "";
        return (
          value.nif?.toString().includes(searchString) ||
          value.cc?.toString().includes(searchString) ||
          value.name.includes(searchString) ||
          value.n_member?.toString().includes(searchString) ||
          value.email.toString().includes(searchString) ||
          usfName?.toLowerCase().includes(searchString) ||
          (firstName.includes(searchStringSplited[0]) &&
            lastName.includes(searchStringSplited[1]))
        );
      });
    }

    handlePrepareTableData(usersFiltered);
  }

  function handleChangeDate(e) {
    setDates(e);
    handleSearchUsers(searchText, e);
  }

  function handleOpenDrawer(data) {
    setIsOpenDrawer(true);
    setSelectedUser(data);
  }

  function handleCloseDrawer() {
    setIsOpenDrawer(false);
    handleGetAdminInfo();
  }

  function handleOpenImportDrawer() {
    setIsOpenImportDrawer(true);
  }

  function handleCloseDrawerImport() {
    setIsOpenImportDrawer(false);
    handleGetAdminInfo();
  }

  function handleCloseExport() {
    setIsOpenExport(false);
  }

  function handleOpenDelete(data) {
    setSelectedUser(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    handleGetAdminInfo();
  }

  function handleOpenActivate(data) {
    setSelectedUser(data);
    setIsOpenActivate(true);
  }

  function handleCloseActivate() {
    setIsOpenActivate(false);
    handleGetAdminInfo();
  }

  return (
    <Row id="admin-list-users" className="dash_card">
      <DrawerExportTable
        open={isOpenExport}
        close={handleCloseExport}
        data={tableData}
        type={"Utilizadores"}
      />
      <Update
        admin={user}
        user={selectedUser}
        open={isOpenDrawer}
        close={handleCloseDrawer}
      />
      <Delete
        user={selectedUser}
        open={isOpenDelete}
        close={handleCloseDelete}
      />
      <Activate
        user={selectedUser}
        open={isOpenActivate}
        close={handleCloseActivate}
      />
      <Import open={isOpenImportDrawer} close={handleCloseDrawerImport} />
      <Col span={24} className="p-20">
        <Row className="ml-0 mr-0">
          <Col span={8} className="d-flex jc-start ai-center">
            <p className="title m-0">Sócios</p>
          </Col>
          <Col span={16} className="d-flex jc-end">
            <Row className="d-flex flex-nowrap w-100">
              <Button
                size="large"
                className="mr-10"
                onClick={handleGetAdminInfo}
              >
                <ReloadOutlined />
              </Button>
              <RangePicker
                onCalendarChange={handleChangeDate}
                className="w-100 mr-10"
              />
              {!user.is_viewer ? (
                <Button
                  size="large"
                  className="mr-10"
                  onClick={handleOpenImportDrawer}
                >
                  Importar
                </Button>
              ) : null}
              <Button
                size="large"
                className="mr-10"
                onClick={() => setIsOpenExport(true)}
              >
                Exportar Tabela
              </Button>
              <Input
                allowClear
                type="text"
                size="large"
                onChange={handleSearchUsers}
                className="search_users"
                placeholder="Procurar sócios"
                suffix={<SearchOutlined />}
                value={searchText}
              />
            </Row>
          </Col>
        </Row>
        <Row className="mt-20">
          <Col span={24}>
            <Table
              scroll={{ x: 1800 }}
              columns={columnsTable}
              dataSource={tableData}
              loading={isLoading}
              rowClassName={(record) => !record.enabled && "disabled-row"}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
