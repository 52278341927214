import { useEffect, useState } from "react";
import { Col, Row, Spin, Tooltip } from "antd";
import axios from "axios";

import internSVG from "../../../assets/icons/Intern.svg";
import mgfSVG from "../../../assets/icons/MGF.svg";
import nurseSVG from "../../../assets/icons/Nurse.svg";
import othersSVG from "../../../assets/icons/Others.svg";
import clinicalSVG from "../../../assets/icons/Clinical-Secretary.svg";
import notPayed from "../../../assets/icons/Not-Payed-Quotas.svg";
import payedAssoc from "../../../assets/icons/quotas-amarelo.svg";
import associatesTotal from "../../../assets/icons/Associates-Total-Number.svg";
import eventsCalendar from "../../../assets/icons/Events-Calendar.svg";
import endpoints from "../../../utils/endoinpts";
import "./Dashboard.css";
import { useOutletContext } from "react-router-dom";
import dayjs from "dayjs";
import { InfoCircleOutlined } from "@ant-design/icons";

export default function DashBoard() {
  const { user } = useOutletContext();

  const [isLoading, setIsLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState([]);
  const [totalUsersValid, setTotalUsersValid] = useState([]);
  const [totalUsersNotValid, setTotalUsersNotValid] = useState([]);
  const [totalEvents, setTotalEvents] = useState([]);
  const [totalDoctors, setTotalDoctors] = useState([]);
  const [totalInterns, setTotalInterns] = useState([]);
  const [totalNurses, setTotalNurses] = useState([]);
  const [totalSecretaries, setTotalSecretaries] = useState([]);
  const [totalOthers, setTotalOthers] = useState([]);
  const [usersNotPayedText, setUsersNotPayedText] = useState(0);

  useEffect(() => {
    handleGetAdminInfo();
  }, []);

  function handleGetAdminInfo() {
    axios
      .get(endpoints.info.getDashboardData)
      .then((res) => {
        let auxDash = res.data;

        let total_users = auxDash[0];
        let total_users_valid = auxDash[1];
        let total_users_not_valid = auxDash[2];
        let users_not_payed = auxDash[3];
        let total_events = auxDash[4];

        let total_doctors = auxDash[5];
        let total_interns = auxDash[6];
        let total_nurses = auxDash[7];
        let total_secretaries = auxDash[8];
        let total_others = auxDash[9];

        let users_active = [];
        let users_inactive = [];

        for (let i = 0; i < users_not_payed.length; i++) {
          let valid_access_date = dayjs(users_not_payed[i].valid_access_date);
          let today = dayjs();
          let yearsAccess = today.diff(valid_access_date, "year");
          if (yearsAccess < 1) {
            users_active.push(users_not_payed[i]);
          } else {
            users_inactive.push(users_not_payed[i]);
          }
        }

        let auxUsersNotPayed = (
          <div>
            <p className="f-12 m-0">Ativos: {users_active.length}</p>
            <p className="f-12 mt-5 mb-0">Inativos: {users_inactive.length}</p>
          </div>
        );

        setUsersNotPayedText(auxUsersNotPayed);
        setTotalUsers(total_users);
        setTotalUsersValid(total_users_valid);
        setTotalUsersNotValid(total_users_not_valid);
        setTotalEvents(total_events);
        setTotalDoctors(total_doctors);
        setTotalInterns(total_interns);
        setTotalNurses(total_nurses);
        setTotalSecretaries(total_secretaries);
        setTotalOthers(total_others);

        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Col span={24}>
          <Row className="dash_card_admin">
            <Col span={6} className="dash_admin_item">
              <img src={associatesTotal} />
              <p>Número total</p>
              <p className="f-40 m-0" style={{ fontWeight: 600, lineHeight: 1 }}>
                {totalUsers.length}
              </p>
            </Col>
            <Col span={6} className="dash_admin_item">
              <img src={payedAssoc} />
              <p>Sócios pagos</p>
              <p className="f-40 m-0" style={{ fontWeight: 600, lineHeight: 1 }}>
                {totalUsersValid.length}
              </p>
            </Col>
            <Col span={6} className="dash_admin_item">
              <img src={notPayed} />
              <p>Quotas por pagar</p>
              <div className="d-flex ai-start">
                <p className="f-40 m-0" style={{ fontWeight: 600, lineHeight: 1 }}>
                  {totalUsersNotValid.length}
                </p>
                <Tooltip className="f-12" placement="right" title={usersNotPayedText}>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            </Col>
            <Col span={6} className="dash_admin_item">
              <img src={eventsCalendar} />
              <p>Eventos</p>
              <p className="f-40 m-0" style={{ fontWeight: 600, lineHeight: 1 }}>
                {totalEvents.length}
              </p>
            </Col>
          </Row>
          <Row justify="space-between" className="mt-20">
            <Col span={4} className="dash_statistics_item">
              <img src={mgfSVG} />
              <p>Médico de Família</p>
              <div className="d-flex ai-start">
                <p className="f-30 m-0" style={{ fontWeight: 600, lineHeight: 1 }}>
                  {totalDoctors.length}
                </p>
                <Tooltip className="f-12" placement="right" title={<p className="f-12 m-0">Aposentados: {totalDoctors?.filter((item) => item.retiree === 1).length}</p>}>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            </Col>
            <Col span={4} className="dash_statistics_item">
              <img src={internSVG} />
              <p>Médico Interno</p>

              <p className="f-30 m-0" style={{ fontWeight: 600, lineHeight: 1 }}>
                {totalInterns.length}
              </p>
            </Col>
            <Col span={4} className="dash_statistics_item">
              <img src={nurseSVG} />
              <p>Enfermeiro</p>
              <div className="d-flex ai-start">
                <p className="f-30 m-0" style={{ fontWeight: 600, lineHeight: 1 }}>
                  {totalNurses.length}
                </p>

                <Tooltip className="f-12" placement="right" title={<p className="f-12 m-0">Aposentados: {totalNurses?.filter((item) => item.retiree === 1).length}</p>}>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            </Col>
            <Col span={4} className="dash_statistics_item">
              <img src={clinicalSVG} />
              <p>Secretário Clínico</p>
              <div className="d-flex ai-start">
                <p className="f-30 m-0" style={{ fontWeight: 600, lineHeight: 1 }}>
                  {totalSecretaries.length}
                </p>
                <Tooltip className="f-12" placement="right" title={<p className="f-12 m-0">Aposentados: {totalSecretaries?.filter((item) => item.retiree === 1).length}</p>}>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            </Col>
            <Col span={4} className="dash_statistics_item">
              <img src={othersSVG} />
              <p>Outros</p>
              <div className="d-flex ai-start">
                <p className="f-30 m-0" style={{ fontWeight: 600, lineHeight: 1 }}>
                  {totalOthers.length}
                </p>

                <Tooltip className="f-12" placement="right" title={<p className="f-12 m-0">Aposentados: {totalOthers?.filter((item) => item.retiree === 1).length}</p>}>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
}
