import React, { useEffect, useState } from "react";
import { Layout, Row, Col } from "antd";
import logoUSF from "../../../assets/images/USF-AN.svg";
const { Header, Sider, Content, Footer } = Layout;
import "./TermsAndConditionsEvents.css";
import { useNavigate } from "react-router-dom";

export default function TermsAndConditionsEvents() {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <Layout className="d-flex flex-column jc-center ai-center main_layout">
      <Header className="d-flex jc-center ai-center w-100">
        <Row className="d-flex ai-center container pb-0">
          <Col span={12} className="d-flex ai-center">
            <img
              src={logoUSF}
              className="logo_header"
              onClick={() => navigate("/")}
            />
          </Col>
        </Row>
      </Header>
      <Content className={`container d-flex flex-column jc-start ai-center`}>
        <Row>
          <Col span={24}>
            <p className="f-20 bold text-center">
              IMPORTANTE: PARA INSCRIÇÃO EM EVENTOS{" "}
            </p>
            <p>
              Todas as suas informações pessoais recolhidas, serão usadas para o
              ajudar a tornar a sua visita ao nosso site o mais produtiva e
              agradável possível.{" "}
            </p>
            <p>
              Caso não se verifique o pagamento da inscrição no evento pela via
              selecionada (apenas para inscrições patrocinadas pela IF e/ou
              pagas pelos Incentivos Institucionais), compromete-se a assumi-la
              perante a Organização.{" "}
            </p>
            <p>
              Se a sua inscrição for paga pelos Incentivos Institucionais e esta
              estiver confirmada, em caso de falta ao evento, assumirá a
              responsabilidade de liquidação da mesma perante a Organização,
              caso a respetiva ARS/ULS se recuse a pagar por falta de
              comparência.{" "}
            </p>
            <p>
              Por favor tenha em consideração que os dados relativos ao “nome” e
              “apelido” anteriormente inseridos, serão os impressos no dia do
              evento e no respetivo certificado (enviado via email).{" "}
            </p>
            <p>
              No dia do evento, por favor apresente o código da sua inscrição,
              que receberá em data mais próxima, para facilitar e agilizar a
              credenciação.{" "}
            </p>
            <p>
              Para evitar filas, por favor chegue atempadamente ao evento.
              Confirme os horários de abertura do secretariado no programa.{" "}
            </p>
            <p className="f-20 bold mt-50 text-center">
              POLÍTICA DE CANCELAMENTO E REEMBOLSO DE INSCRIÇÕES{" "}
            </p>
            <p>
              O participante pode, a qualquer momento, cancelar a inscrição num
              Programa de Formação ou evento previsto, solicitando a sua
              intenção através de email{" "}
              <a href="mailto:geral@usf-an.pt">geral@usf-an.pt</a>. Deverá, no
              entanto, ter em conta as condições e montantes de reembolso do
              valor da inscrição mediante pedido de cancelamento, apresentadas
              abaixo:{" "}
            </p>
            <ol>
              <li>
                Se o pedido for efetuado com uma antecedência mínima de 60 dias
                da data de início/realização do Programa de Formação ou evento,
                será efetuado o reembolso total do valor da inscrição;
              </li>
              <li>
                Se o pedido for efetuado com uma antecedência mínima de 30 dias
                da data de início/realização do Programa de Formação ou evento,
                será efetuado o reembolso de 50% do valor da inscrição;
              </li>
              <li>
                Se o pedido for efetuado após o trigésimo dia anterior à data de
                início/realização do Programa de Formação ou evento, não haverá
                lugar a qualquer reembolso.
              </li>
            </ol>
            <p>
              Em qualquer dos casos, será deduzido o montante de 20 euros
              relativo a despesas administrativas mais os impostos e tarifas
              aplicáveis à operação financeira de reembolso. Os reembolsos serão
              processados no prazo de 30 dias a contar da data de receção do
              pedido de cancelamento de inscrição. Para o efeito, o participante
              deverá anexar ao pedido de cancelamento o IBAN da conta para a
              qual pretende que a transferência seja efetuada.{" "}
            </p>
            <p>
              A falta de comparência em programas de formação ou eventos, sem
              aviso prévio, implica a não retribuição de qualquer valor pago.{" "}
            </p>
            <p className="f-20 bold mt-50 text-center">
              RECOLHA E TRATAMENTO DE IMAGEM E SOM{" "}
            </p>
            <p>
              Ao aceitar estes termos e condições, está a aceitar que a
              organização tem o direito de captar e difundir fotografias ou
              gravações áudio e/ou vídeo para efeitos de comunicação e marketing
              do(s) evento(s) em questão e/ou da própria organização.{" "}
            </p>
            <p>
              No entanto, o titular dos dados tem o direito de rejeitar esse
              tratamento, devendo para tal notificar a USF-AN com essa intenção
              através do email{" "}
              <a href="mailto:geral@usf-an.pt">geral@usf-an.pt</a>.{" "}
            </p>
            <p className="f-20 bold mt-50 text-center">
              PARA SUBSCRIÇÕES DE QUOTAS{" "}
            </p>
            <p>
              Todas as suas informações pessoais recolhidas, serão usadas para o
              ajudar a tornar a sua visita ao nosso site o mais produtiva e
              agradável possível.{" "}
            </p>
            <p>
              Apenas com o pagamento da respetiva quota, passará a ser um
              associado “Ativo”, passando a usufruir de todas as vantagens.{" "}
            </p>
          </Col>
        </Row>
      </Content>
      <Footer>
        <Row className="w-100">
          <Col span={24} className="text-center footer">
            <p>
              © Copyright 2024. USF-AN. Todos os direitos reservados |{" "}
              <a href="https://usf-an.pt/termos-e-condicoes/" target="_blank">
                Termos e condições
              </a>{" "}
              |{" "}
              <a href="https://usf-an.pt/termos-e-condicoes/" target="_blank">
                Política de privacidade
              </a>
            </p>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
}
