import {
  Col,
  Form,
  Row,
  Input,
  DatePicker,
  Select,
  Checkbox,
  Radio,
  Upload,
  Button,
} from "antd";

import geoAPI from "../../../utils/districts";

import "./Register.css";
import { useEffect, useState } from "react";
import config from "../../../utils/config";
import { UploadOutlined } from "@ant-design/icons";

export default function RegisterStepThree({
  form,
  handleSubmit,
  handleSubmitFailed,
  professions,
  roles,
  ars,
  aces,
  usf,
  uploadProps,
}) {
  const [acesFiltered, setACESFiltered] = useState([]);
  const [usfFiltered, setUSFFiltered] = useState([]);

  function handleChangeSelectARS(value) {
    form.setFieldsValue({ aces: null });
    form.setFieldsValue({ usf: null });
    let aux = [];
    for (let i = 0; i < aces.length; i++) {
      if (aces[i].id_ars === value) {
        aux.push(aces[i]);
      }
    }

    setACESFiltered(aux);
  }

  function handleChangeSelectACES(value) {
    form.setFieldsValue({ usf: null });
    let aux = [];
    for (let i = 0; i < usf.length; i++) {
      if (usf[i].id_aces === value) {
        aux.push(usf[i]);
      }
    }

    aux.push({ id: "other", name: "Outro" });

    setUSFFiltered(aux);
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      onFinishFailed={handleSubmitFailed}
    >
      <Row className="d-flex jc-center ai-center">
        <Col xs={24} sm={20} md={20} lg={20} xl={20}>
          <Form.Item
            name="professional_name"
            label="Nome profissional"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
          >
            <Input size="large" placeholder="Nome" />
          </Form.Item>
          <Form.Item
            name="profession"
            label="Profissão"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
          >
            <Select
              size="large"
              showSearch
              placeholder="Profissão"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              getPopupContainer={(trigger) => trigger.parentNode}
              options={professions.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="retiree"
            label="Está neste momento aposentado?"
            rules={[
              {
                required: true,
                message: "Este campo é obrigatório",
              },
            ]}
          >
            <Radio.Group>
              <Radio value={1}>Sim</Radio>
              <Radio value={0}>Não</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.retiree !== currentValues.retiree
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("retiree") === 1 ? (
                <Form.Item
                  name="retiree_file"
                  label="Insira um documento que comprove a sua situação acima referida."
                  rules={[
                    {
                      required: true,
                      message: "Este campo é obrigatório",
                    },
                  ]}
                >
                  <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>
                      Selecionar ficheiro
                    </Button>
                  </Upload>
                </Form.Item>
              ) : null
            }
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.profession !== currentValues.profession
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("profession") === 2 ? (
                <Form.Item
                  name="boarding_year"
                  label="Ano de início de internato"
                  rules={[
                    { required: true, message: "Este campo é obrigatório" },
                  ]}
                >
                  <DatePicker
                    picker="year"
                    size="large"
                    placeholder="Ano de início de internato"
                  />
                </Form.Item>
              ) : null
            }
          </Form.Item>
          <Form.Item
            name="role"
            label="Cargo na unidade"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
          >
            <Select
              size="large"
              showSearch
              placeholder="Cargo na unidade"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              getPopupContainer={(trigger) => trigger.parentNode}
              options={roles.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="work_district"
            label="Distrito da Unidade"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
          >
            <Select
              size="large"
              showSearch
              placeholder="Distrito da Unidade"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.value ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              getPopupContainer={(trigger) => trigger.parentNode}
              options={geoAPI.districts.map((item) => ({
                value: item.name,
                label: item.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="id_ars"
            label="ARS"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
          >
            <Select
              size="large"
              showSearch
              placeholder="ARS"
              optionFilterProp="children"
              onChange={handleChangeSelectARS}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              getPopupContainer={(trigger) => trigger.parentNode}
              options={ars.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="id_aces"
            label="ACeS"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
          >
            <Select
              size="large"
              showSearch
              placeholder="ACeS"
              optionFilterProp="children"
              onChange={handleChangeSelectACES}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              getPopupContainer={(trigger) => trigger.parentNode}
              options={acesFiltered.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="id_usf"
            label="Unidade"
            rules={[{ required: true, message: "Este campo é obrigatório" }]}
          >
            <Select
              size="large"
              showSearch
              placeholder="Unidade"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              getPopupContainer={(trigger) => trigger.parentNode}
              options={usfFiltered.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.id_usf !== currentValues.id_usf
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("id_usf") === "other" ? (
                <Form.Item
                  name="new_usf"
                  rules={[
                    { required: true, message: "Este campo é obrigatório" },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Escreva aqui o nome da Unidade"
                  />
                </Form.Item>
              ) : null
            }
          </Form.Item>
          <Form.Item
            name="acceptance"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error("Este campo é obrigatório")),
              },
            ]}
            valuePropName="checked"
          >
            <Checkbox>
              Li e Aceito o{" "}
              <a
                target="_blank"
                href={`${config.server_ip}files/REGULAMENTO-GERAL-DOS-ASSOCIADOS_AG2024.pdf`}
              >
                Regulamento Geral dos Associados
              </a>
              *
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
