import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Form, Row, notification, Button } from "antd";
import { Steps } from "antd";
import axios from "axios";
import dayjs from "dayjs";

import config from "../../../utils/config";
import endpoints from "../../../utils/endoinpts";
import utils from "../../../utils/utils";
import RegisterStepOne from "./RegisterStepOne";
import RegisterStepTwo from "./RegisterStepTwo";
import RegisterStepThree from "./RegisterStepThree";

import logoUSF from "../../../assets/images/USF-AN.svg";
import logoUSFBackground from "../../../assets/images/logo-usg-login-criar-conta.svg";

import "./Register.css";

function Register() {
  const [currentStep, setCurrentStep] = useState(0);
  const [ars, setARS] = useState([]);
  const [aces, setACES] = useState([]);
  const [usf, setUSF] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [formStepOne] = Form.useForm();
  const [formStepTwo] = Form.useForm();
  const [formStepThree] = Form.useForm();

  const navigate = useNavigate();

  const uploadProps = {
    accept: "application/pdf",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: [],
  };

  useEffect(() => {
    axios
      .get(`${config.server_ip}${endpoints.info.getInfo}`)
      .then((result) => {
        setARS(result.data[0]);
        setACES(result.data[1]);
        setUSF(result.data[2]);
        setProfessions(result.data[3]);
        setRoles(result.data[4]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function handleChangeStep(value) {
    setCurrentStep(value);
  }

  function handleSubmitFormStepOne() {
    setIsButtonLoading(true);
    let values = formStepOne.getFieldsValue(true);
    if (utils.validateNIF(values.nif)) {
      axios
        .get(`${config.server_ip}${endpoints.auth.verifyUser}`, {
          params: {
            email: values.email,
            nif: values.nif,
          },
        })
        .then((res) => {
          if (
            !res.data.user_registered &&
            !res.data.nif_exists &&
            !res.data.email_exists
          ) {
            setTimeout(() => {
              setCurrentStep(currentStep + 1);
              setIsButtonLoading(false);
            }, 1500);
          } else {
            setIsButtonLoading(false);
            notification.error({
              message: "E-mail ou NIF",
              description:
                "O e-mail ou nif inserido já existe, por favor verifique os seus dados",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setIsButtonLoading(false);
        });
    } else {
      setIsButtonLoading(false);
      notification.error({
        message: "NIF inválido",
        description:
          "Verifique por favor o NIF que inseriu, poderá estar inválido.",
      });
    }
  }

  function handleSubmitFormStepTwo() {
    setIsButtonLoading(true);
    setTimeout(() => {
      setCurrentStep(currentStep + 1);
      setIsButtonLoading(false);
    }, 1500);
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  function handleSubmit() {
    setIsButtonLoading(true);
    let valuesFormOne = formStepOne.getFieldsValue(true);
    let valuesFormTwo = formStepTwo.getFieldsValue(true);
    let valuesFormThree = formStepThree.getFieldsValue(true);

    let data = {
      name: valuesFormOne.name,
      email: valuesFormOne.email,
      password: valuesFormOne.password,
      gender: valuesFormOne.gender,
      birth_date: dayjs(valuesFormOne.birth_date).format("YYYY-MM-DD"),
      phone: valuesFormOne.phone,
      cc: valuesFormOne.cc,
      nif: valuesFormOne.nif,
      address: valuesFormTwo.address,
      post_code: valuesFormTwo.post_code,
      district: valuesFormTwo.district,
      county: valuesFormTwo.county,
      parish: valuesFormTwo.parish,
      professional_name: valuesFormThree.professional_name,
      id_profession: valuesFormThree.profession,
      retiree: valuesFormThree.retiree,
      profession_name: professions.filter(
        (item) => valuesFormThree.profession === item.id
      )[0].name,
      id_role: valuesFormThree.role,
      boarding_year: dayjs(valuesFormThree.boarding_year).format("YYYY"),
      id_ars: valuesFormThree.id_ars,
      id_aces: valuesFormThree.id_aces,
      id_usf: valuesFormThree.id_usf,
      new_usf: valuesFormThree.new_usf,
      work_district: valuesFormThree.work_district,
    };

    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("data", JSON.stringify(data));

    fetch(`${config.server_ip}${endpoints.auth.register}`, {
      method: "POST",
      body: formData,
    })
      .then((result) => result.json())
      .then((res) => {
        console.log(res);
        if (res.user_registered) {
          notification.success({
            message: "Registado",
            description:
              "Utilizador registado com sucesso. Pode fazer login para descobrir mais sobre a nossa plataforma",
          });
          setTimeout(() => {
            setIsButtonLoading(false);
            navigate("/login");
          }, 2000);
        } else {
          setIsButtonLoading(false);
          notification.error({
            message: "Erro",
            description:
              "Alguma coisa correu mal, tente novamente mais tarde, por favor.",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
      });
  }

  return (
    <div className="pb-100">
      <Row className="w-100">
        <Col span={24}>
          <Steps
            type="navigation"
            size="small"
            current={currentStep}
            onChange={handleChangeStep}
            className="register-steps"
            items={[
              {
                title: "1. Dados de contacto",
              },
              {
                title: "2. Morada",
              },
              {
                title: "3. Dados Profissionais",
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="d-flex jc-center ai-center">
        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
          <h1 className="text-center mb-0" style={{ color: "#4A4C4D" }}>
            Registo
          </h1>
          <p
            className="text-center"
            style={{ marginTop: "5px", color: "#BDBCBC" }}
          >
            Coloque a baixo os seus dados de inscrição
          </p>
          {currentStep === 0 && (
            <RegisterStepOne
              form={formStepOne}
              handleSubmit={handleSubmitFormStepOne}
              handleSubmitFailed={handleWarningMissingFields}
            />
          )}
          {currentStep === 1 && (
            <RegisterStepTwo
              form={formStepTwo}
              handleSubmit={handleSubmitFormStepTwo}
              handleSubmitFailed={handleWarningMissingFields}
            />
          )}
          {currentStep === 2 && (
            <RegisterStepThree
              form={formStepThree}
              uploadProps={uploadProps}
              handleSubmit={handleSubmit}
              handleSubmitFailed={handleWarningMissingFields}
              professions={professions}
              roles={roles}
              ars={ars}
              aces={aces}
              usf={usf}
            />
          )}
        </Col>
      </Row>
      <Row>
        {currentStep > 0 && (
          <Col span={12} className="d-flex jc-end">
            <Button
              type="primary"
              size="large"
              className="f-14 minw-175 bg-white border-green green mr-10"
              onClick={() => handleChangeStep(currentStep - 1)}
            >
              Anterior
            </Button>
          </Col>
        )}
        <Col
          span={currentStep > 0 ? 12 : 24}
          className={currentStep === 0 ? "d-flex jc-center" : "d-flex jc-start"}
        >
          {currentStep === 0 && (
            <Button
              type="primary"
              size="large"
              className="f-14 minw-175"
              loading={isButtonLoading}
              onClick={() => formStepOne.submit()}
            >
              Seguinte
            </Button>
          )}
          {currentStep === 1 && (
            <Button
              type="primary"
              size="large"
              className="f-14 minw-175 ml-10"
              loading={isButtonLoading}
              onClick={() => formStepTwo.submit()}
            >
              Seguinte
            </Button>
          )}
          {currentStep === 2 && (
            <Button
              type="primary"
              size="large"
              className="f-14 minw-175 ml-10"
              loading={isButtonLoading}
              onClick={() => formStepThree.submit()}
            >
              Registar
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Register;
