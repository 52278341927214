import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Space,
  Upload,
  Form,
  Input,
  Select,
  InputNumber,
  Divider,
  Button,
  DatePicker,
  Switch,
  Drawer,
  Spin,
} from "antd";
import {
  UploadOutlined,
  DeleteOutlined,
  PlusOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import ReactQuill from "react-quill";

import { ReactComponent as IconUpload } from "../../../../assets/icons/svg-upload.svg";
import config from "../../../../utils/config";
import endpoints from "../../../../utils/endoinpts";
import "react-quill/dist/quill.snow.css";
import "./Update.css";

const { Dragger } = Upload;
const { RangePicker } = DatePicker;

export default function Update({ user, data, open, close }) {
  const [form] = Form.useForm();

  const [event, setEvent] = useState({});
  const [deletedPrices, setDeletedPrices] = useState([]);
  const [deletedOptions, setDeletedOptions] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileListPDF, setFileListPDF] = useState([]);
  const [filePreview, setFilePreview] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      handleGetEventInfo(data);
    }
  }, [data]);

  const uploadProps = {
    accept: "image/*",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      handlePreview(file);
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: [],
  };

  /* Upload setup for pdf files */
  const props = {
    accept: "application/pdf",
    onRemove: (file) => {
      const index = fileListPDF.indexOf(file);
      const newFileListPDF = fileListPDF.slice();
      newFileListPDF.splice(index, 1);
      setFileListPDF(newFileListPDF);
    },
    beforeUpload: (file) => {
      setFileListPDF([file]);
      return false;
    },
    fileList: fileListPDF,
    defaultFileList: [],
  };

  function handleGetEventInfo(data) {
    setIsLoading(true);
    axios
      .get(endpoints.event.readById, {
        params: { id: data.id },
      })
      .then((res) => {
        let professionsData = res.data.professions;
        if (res.data.event.length > 0) {
          let event = res.data.event[0];
          let prices = res.data.event_prices;
          let options = res.data.event_options;

          let aux = {
            id: event.id,
            image: event.image,
            date: [dayjs(event.date_start), dayjs(event.date_end)],
            enroll_date_limit: dayjs(event.enroll_date_limit),
            name: event.name,
            description: event.description,
            price: prices,
            options: options,
            id_file: event.id_file,
            file_name: event.file_name,
            total_professionals: event.total_professionals,
            total_professionals_left: event.total_professionals_left,
            is_active: event.is_active === 1,
            institutional_incentives: event.institutional_incentives === 1,
            promotion: event.promotion === 1,
            promotion_date: event.promotion_date
              ? dayjs(event.promotion_date)
              : null,
            promotion_value: event.promotion_value,
          };

          if (event.id_file) {
            setFileListPDF([{ uid: event.id_file, name: event.file_name }]);
          } else {
            setFileListPDF([{ uid: "not_new", name: "Ficheiro-Exemplo.pdf" }]);
          }

          form.setFieldsValue(aux);
          let stringImage = `${config.server_ip}media/${event.image}`;
          setFilePreview(stringImage);
          setEvent(aux);
          setProfessions(professionsData);
          setIsLoading(false);
        } else {
          setEvent([]);
          setProfessions(professionsData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function handlePreview(file) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleSubmitEvent(values) {
    setIsButtonLoading(true);
    let eventData = values;
    const formData = new FormData();

    if (
      Number(event.total_professionals) !== Number(values.total_professionals)
    ) {
      if (
        Number(event.total_professionals) < Number(values.total_professionals)
      ) {
        let calc =
          event.total_professionals_left +
          (Number(values.total_professionals) -
            Number(event.total_professionals));
        eventData.total_professionals_left = calc < 0 ? 0 : calc;
      } else {
        let calc =
          event.total_professionals_left -
          (Number(event.total_professionals) -
            Number(values.total_professionals));
        eventData.total_professionals_left = calc < 0 ? 0 : calc;
      }
    }

    for (let i = 0; i < eventData.price.length; i++) {
      if (eventData.price[i].id) {
        let filteredPrice = event.price.filter(
          (item) => item.id === eventData.price[i].id
        )[0];
        if (
          Number(filteredPrice.quantity) !== Number(eventData.price[i].quantity)
        ) {
          if (
            Number(filteredPrice.quantity) < Number(eventData.price[i].quantity)
          ) {
            let calc =
              filteredPrice.quantity_left +
              (Number(eventData.price[i].quantity) -
                Number(filteredPrice.quantity));
            eventData.price[i].quantity_left = calc < 0 ? 0 : calc;
          } else {
            let calc =
              filteredPrice.quantity_left -
              (Number(filteredPrice.quantity) -
                Number(eventData.price[i].quantity));
            eventData.price[i].quantity_left = calc < 0 ? 0 : calc;
          }
        }
      } else {
        eventData.price[i].quantity_left = eventData.price[i].quantity;
      }
    }

    for (let i = 0; i < eventData.options.length; i++) {
      if (eventData.options[i].id) {
        let filteredOption = event.options.filter(
          (item) => item.id === eventData.options[i].id
        )[0];
        if (
          Number(filteredOption.quantity) !==
          Number(eventData.options[i].quantity)
        ) {
          if (
            Number(filteredOption.quantity) <
            Number(eventData.options[i].quantity)
          ) {
            let calc =
              filteredOption.quantity_left +
              (Number(eventData.options[i].quantity) -
                Number(filteredOption.quantity));
            eventData.options[i].quantity_left = calc < 0 ? 0 : calc;
          } else {
            let calc =
              filteredOption.quantity_left -
              (Number(filteredOption.quantity) -
                Number(eventData.options[i].quantity));
            eventData.options[i].quantity_left = calc < 0 ? 0 : calc;
          }
        }
      } else {
        eventData.options[i].quantity_left = eventData.options[i].quantity;
      }
    }

    eventData.institutional_incentives = eventData.institutional_incentives
      ? 1
      : 0;
    eventData.is_active = eventData.is_active ? 1 : 0;
    eventData.promotion = eventData.promotion ? 1 : 0;

    fileList.forEach((file) => {
      formData.append("file", file);
    });

    fileListPDF.forEach((file) => {
      if (file.uid !== "not_new") {
        formData.append("file_pdf", file);
      } else {
        eventData.id_file = event.id_file;
      }
    });

    formData.append("data", JSON.stringify(eventData));
    formData.append(
      "deleted_data",
      JSON.stringify({
        deleted_prices: deletedPrices,
        deleted_options: deletedOptions,
      })
    );
    formData.append("professions", JSON.stringify(professions));

    fetch(`${config.server_ip}${endpoints.event.update}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          setFileList([]);
          setFileListPDF([]);
          close();
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
      });
  }

  function handleDeletePrices(n, remove) {
    let formPriceFilter = form.getFieldValue("price");
    let deleted = formPriceFilter[n];
    if (deleted) {
      let aux = JSON.parse(JSON.stringify(deletedPrices));
      aux.push(deleted);
      setDeletedPrices(aux);
    }
    remove(n);
  }

  function handleDeleteOptions(n, remove) {
    let formOptionsFilter = form.getFieldValue("options");
    let deleted = formOptionsFilter[n];
    if (deleted) {
      let aux = JSON.parse(JSON.stringify(deletedOptions));
      aux.push(deleted);
      setDeletedOptions(aux);
    }
    remove(n);
  }

  function handleCloseDrawer() {
    setIsLoading(true);
    close();
    setFileList([]);
    setFileListPDF([]);
    setFilePreview("");
  }

  return (
    <Drawer
      title={`Editar evento`}
      width={1400}
      onClose={handleCloseDrawer}
      open={open}
      maskClosable={false}
    >
      {Object.keys(event).length > 0 && (
        <Spin spinning={isLoading}>
          <Row id="admin-create-event" className="dash_card" gutter={24}>
            <Col span={24}>
              <p className="title m-0">Editar Evento</p>
              <p className="dash_text bold">Faça upload da sua imagem</p>
              <p className="dash_text gray">Tamanho máximo de upload (2 MB)</p>
              <Row className="ml-0 mr-0 mt-20">
                <Col span={7}>
                  <Dragger
                    disabled={user.is_viewer}
                    {...uploadProps}
                    className={"dragger hasBgImage"}
                    style={{ backgroundImage: `url(${filePreview})` }}
                  >
                    <p className="ant-upload-drag-icon">
                      <IconUpload className="icon icon-upload" />
                    </p>
                    <p className="ant-upload-text text dash_text bold">
                      Upload da imagem
                    </p>
                    <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                  </Dragger>
                </Col>
                <Col span={17}>
                  <Form
                    form={form}
                    id="dynamic_form_nest_item"
                    onFinish={handleSubmitEvent}
                    className="form"
                    disabled={user.is_viewer}
                  >
                    <Form.Item hidden name="image">
                      <Input type="text" />
                    </Form.Item>
                    <Form.Item hidden name="id">
                      <Input type="text" />
                    </Form.Item>
                    <Row gutter={[24]}>
                      <Col span={16}>
                        <Form.Item
                          name="name"
                          labelCol={{ span: 24 }}
                          label="Título"
                          rules={[
                            {
                              required: true,
                              message: "Insira um título para o evento",
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Título do Evento" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="institutional_incentives"
                          labelCol={{ span: 24 }}
                          label="Incentivos Institucionais"
                          valuePropName="checked"
                        >
                          <Switch size="large" />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item
                          name="is_active"
                          labelCol={{ span: 24 }}
                          label="Ativo"
                          valuePropName="checked"
                        >
                          <Switch size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[24]}>
                      <Col span={16}>
                        <Form.Item
                          labelCol={{ span: 24 }}
                          name="date"
                          label="Data/Hora"
                          rules={[
                            {
                              required: true,
                              message: "Selecione um horário para o evento",
                            },
                          ]}
                        >
                          <RangePicker
                            size="large"
                            showTime={{ format: "HH:mm" }}
                            format="YYYY-MM-DD HH:mm"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          labelCol={{ span: 24 }}
                          name="enroll_date_limit"
                          label="Data limite de inscrição"
                          rules={[
                            {
                              required: true,
                              message: "Selecione uma data limite de inscrição",
                            },
                          ]}
                        >
                          <DatePicker size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Insira uma breve descrição para o evento",
                        },
                      ]}
                    >
                      <ReactQuill
                        theme="snow"
                        placeholder="Descrição do evento"
                      />
                    </Form.Item>
                    <Divider dashed className="bar" />
                    <Form.List name="price">
                      {(fields, { add, remove }) => {
                        return (
                          <Row gutter={[24]}>
                            <Col span={5}>
                              <Button size="large" onClick={() => add()}>
                                <PlusOutlined /> Adicionar
                              </Button>
                            </Col>
                            <Col span={19} className="form_list_prices">
                              {fields.map(
                                ({ key, name, ...restField }, index) => {
                                  return (
                                    <Row
                                      key={key}
                                      gutter={[24]}
                                      align="center"
                                      className={`${index > 0 ? "mt-20" : ""}`}
                                    >
                                      <Col span={23}>
                                        <Row gutter={[24]}>
                                          <Col span={11}>
                                            <Form.Item
                                              noStyle
                                              shouldUpdate={(
                                                prevValues,
                                                currentValues
                                              ) =>
                                                prevValues.price !==
                                                currentValues.price
                                              }
                                            >
                                              {({ getFieldValue }) => (
                                                <Form.Item
                                                  {...restField}
                                                  name={[name, "id_profession"]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        "Selecione uma opção",
                                                    },
                                                  ]}
                                                >
                                                  <Select
                                                    size="large"
                                                    showSearch
                                                    placeholder="Selecione uma profissão"
                                                    optionFilterProp="children"
                                                    filterOption={(
                                                      input,
                                                      option
                                                    ) =>
                                                      (option?.label ?? "")
                                                        .toLowerCase()
                                                        .includes(
                                                          input.toLowerCase()
                                                        )
                                                    }
                                                    options={professions?.map(
                                                      (item, index) => ({
                                                        value: item.id,
                                                        label: item.name,
                                                        disabled:
                                                          getFieldValue(
                                                            "price"
                                                          ).filter(
                                                            (c) =>
                                                              c?.id_profession ===
                                                              item.id
                                                          ).length > 0,
                                                      })
                                                    )}
                                                    getPopupContainer={(
                                                      trigger
                                                    ) => trigger.parentNode}
                                                  />
                                                </Form.Item>
                                              )}
                                            </Form.Item>
                                          </Col>
                                          <Col
                                            span={7}
                                            align="center"
                                            justify="center"
                                          >
                                            <Form.Item
                                              {...restField}
                                              label="Preço"
                                              name={[name, "price"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Preço",
                                                },
                                              ]}
                                            >
                                              <InputNumber
                                                size="large"
                                                min={0}
                                                placeholder="0,00 €"
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col span={6} align="end">
                                            <Form.Item
                                              {...restField}
                                              label="Qtd"
                                              name={[name, "quantity"]}
                                            >
                                              <InputNumber
                                                size="large"
                                                min={0}
                                                placeholder="0"
                                              />
                                            </Form.Item>
                                            <Form.Item
                                              {...restField}
                                              label="Qtd"
                                              name={[name, "quantity_left"]}
                                              hidden
                                            >
                                              <InputNumber
                                                size="large"
                                                min={0}
                                                placeholder="0"
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col span={1} className="icon_delete_div">
                                        <DeleteOutlined
                                          className={`icon icon-delete ${
                                            fields.length === 1
                                              ? "disabled"
                                              : ""
                                          }`}
                                          disabled={fields.length === 1}
                                          onClick={() =>
                                            handleDeletePrices(name, remove)
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  );
                                }
                              )}
                            </Col>
                          </Row>
                        );
                      }}
                    </Form.List>

                    <Row className="mt-20">
                      <Col span={5}></Col>
                      <Col span={19} className="form_list_prices">
                        <Row>
                          <Col span={11}></Col>
                          <Col span={12} className="d-flex justify-content-end">
                            <Form.Item
                              label="Máx. inscritos"
                              name="total_professionals"
                            >
                              <InputNumber
                                size="large"
                                min={0}
                                placeholder="0"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Divider dashed className="bar" />
                    <Form.List name="options">
                      {(fields, { add, remove }) => (
                        <Row gutter={[24]}>
                          <Col span={5}>
                            <Button size="large" onClick={() => add()}>
                              <PlusOutlined /> Adicionar
                            </Button>
                          </Col>
                          <Col span={19} className="form_list_options">
                            {fields.map(
                              ({ key, name, ...restField }, index) => (
                                <Row
                                  key={key}
                                  style={{
                                    display: "flex",
                                    marginBottom: 8,
                                  }}
                                  gutter={[24]}
                                >
                                  <Col span={23}>
                                    <Row gutter={[24]}>
                                      <Col span={11}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "name"]}
                                        >
                                          <Input
                                            size="large"
                                            placeholder="Opções"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Form.Item
                                        noStyle
                                        shouldUpdate={(
                                          prevValues,
                                          currentValues
                                        ) =>
                                          prevValues.options !==
                                          currentValues.options
                                        }
                                      >
                                        {({ getFieldValue }) => (
                                          <>
                                            <Col span={7} align="center">
                                              <Form.Item
                                                {...restField}
                                                label="Preço"
                                                name={[name, "price"]}
                                                rules={[
                                                  {
                                                    required:
                                                      getFieldValue("options")[
                                                        name
                                                      ] &&
                                                      getFieldValue("options")[
                                                        name
                                                      ].name &&
                                                      getFieldValue("options")[
                                                        name
                                                      ].name.length > 0,
                                                    message: "Insira um preço",
                                                  },
                                                ]}
                                              >
                                                <InputNumber
                                                  size="large"
                                                  min={0}
                                                  placeholder="0,00 €"
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col span={6} align="end">
                                              <Form.Item
                                                {...restField}
                                                label="Qtd"
                                                name={[name, "quantity"]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Insira uma quantidade",
                                                  },
                                                ]}
                                              >
                                                <InputNumber
                                                  size="large"
                                                  min={0}
                                                  placeholder="0"
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                {...restField}
                                                label="Qtd"
                                                name={[name, "quantity_left"]}
                                                hidden
                                              >
                                                <InputNumber
                                                  size="large"
                                                  min={0}
                                                  placeholder="0"
                                                />
                                              </Form.Item>
                                            </Col>
                                          </>
                                        )}
                                      </Form.Item>
                                    </Row>
                                  </Col>

                                  <Col span={1} className="icon_delete_div">
                                    <DeleteOutlined
                                      className={`icon icon-delete ${
                                        fields.length === 1 ? "disabled" : ""
                                      }`}
                                      onClick={() =>
                                        handleDeleteOptions(name, remove)
                                      }
                                    />
                                  </Col>
                                </Row>
                              )
                            )}
                          </Col>
                        </Row>
                      )}
                    </Form.List>
                    <Divider dashed className="bar" />
                    <Row gutter={[24]}>
                      <Col
                        span={5}
                        className="form_list_options d-flex justify-content-center align-items-center"
                      >
                        <Form.Item
                          label="Promoção"
                          name="promotion"
                          valuePropName="checked"
                        >
                          <Switch size="large" />
                        </Form.Item>
                      </Col>

                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.promotion !== currentValues.promotion
                        }
                      >
                        {({ getFieldValue }) => (
                          <>
                            <Col span={14} className="form_list_options">
                              <Form.Item
                                label="Data"
                                name="promotion_date"
                                rules={[
                                  {
                                    required: getFieldValue("promotion"),
                                    message: "Insira uma data",
                                  },
                                ]}
                              >
                                <DatePicker size="large" />
                              </Form.Item>
                            </Col>
                            <Col span={5} className="form_list_options">
                              <Form.Item
                                label="Valor"
                                name="promotion_value"
                                rules={[
                                  {
                                    required: getFieldValue("promotion"),
                                    message: "Insira um preço",
                                  },
                                ]}
                              >
                                <InputNumber
                                  size="large"
                                  min={0}
                                  placeholder="0"
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Form.Item>
                    </Row>
                    <Divider dashed className="bar" />
                    <Row gutter={[24]}>
                      <Col span={5} className="d-flex justify-content-start">
                        <PaperClipOutlined />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Anexar ficheiro
                        </div>
                      </Col>
                      <Col span={19}>
                        <Row
                          style={{
                            display: "flex",
                          }}
                          className="form_list_options"
                        >
                          <Col span={24} align="end" className="upload_col">
                            <Upload {...props}>
                              <Button
                                size="large"
                                icon={<UploadOutlined />}
                                className="btn-send-pdf"
                              >
                                Upload
                              </Button>
                            </Upload>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              {!user.is_viewer ? (
                <Row className="ml-0 mr-0 mt-20">
                  <Col span={24} align="center">
                    <Button
                      type="primary"
                      className="submit"
                      onClick={form.submit}
                      loading={isButtonLoading}
                    >
                      Guardar
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Spin>
      )}
    </Drawer>
  );
}
