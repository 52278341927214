import React, { useEffect } from 'react';
import {
  Outlet,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

export default function UserAuth() {

  const { user } = useOutletContext();
  const { shoppingCart } = useOutletContext();
  const { handleUpdateShoppingCart } = useOutletContext();
  const { handleUpdateUser } = useOutletContext();

  const navigate = useNavigate()

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      if (user.is_admin || user.is_viewer) {
        navigate('/admin');
      }
    }
  }, [user]);

  return (
    <Outlet context={{ user, shoppingCart, handleUpdateShoppingCart, handleUpdateUser }} />
  );
}