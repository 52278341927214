import { useEffect, useState } from "react";
import { Col, Form, Row, notification, Input, Button, Divider, Spin } from "antd";
import axios from 'axios';
import { useForm } from "antd/es/form/Form";
import ReactQuill from "react-quill";
import endpoints from "../../../utils/endoinpts";
import './Settings.css';

export default function SettingsForm({ data }) {

  const [isSaving, setIsSaving] = useState(false);

  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue(data)
  }, [data]);

  function handleSubmit(values) {
    axios.post(endpoints.info.updateConfig, {
      data: values
    }).then((res) => {
      notification.success({
        message: `Sucesso`,
        description: 'Dados foram gravados com sucesso!'
      });
    }).catch((err) => {
      console.log(err);
      notification.success({
        message: `Erro`,
        description: 'Aconteceu algum erro! Por favor, tente novamente.'
      });
    })
  }

  return (
    <Row className="dash_card">
      <p className="settings_title">Formulário Departamento Pareceres</p>
      <Divider className="settings_divider" />
      <Form
        form={form}
        id="settings-parecer-form"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="id"
              label="id"
              hidden
            >
              <Input size="large" placeholder='id' />
            </Form.Item>
            <Form.Item
              name="type"
              label="type"
              initialValue="form_department"
              hidden
            >
              <Input size="large" placeholder='config' />
            </Form.Item>
            <Form.Item
              name="title"
              label="Título"
            >
              <Input size="large" placeholder='Título Formulário Departamento de Pareceres' />
            </Form.Item>
            <Form.Item
              name="text"
              label="Texto"
            >
              <ReactQuill theme="snow" placeholder='Texto Form Parecer' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} align="end">
            <Button
              htmlType="submit"
              className="button-register"
              loading={isSaving}
            >
              Guardar alterações
            </Button>
          </Col>
        </Row>
      </Form>
    </Row>
  );
}
