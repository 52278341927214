import { useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  notification,
  Input,
  Button,
  Divider,
  Spin,
  InputNumber,
  Select,
  Tabs,
} from "antd";
import axios from "axios";
import { useForm } from "antd/es/form/Form";
import ReactQuill from "react-quill";
import endpoints from "../../../utils/endoinpts";
import mbImage from "../../../assets/icons/MB.svg";
import mbWayImage from "../../../assets/icons/MB-Way.svg";
import bankImage from "../../../assets/icons/Bank.svg";
import "./Settings.css";

export default function SettingsQuotes() {
  const [data, setData] = useState({});
  const [professions, setProfessions] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const [formMB] = Form.useForm();
  const [formMBRetiree] = Form.useForm();
  const [formMBWay] = Form.useForm();
  const [formMBWayRetiree] = Form.useForm();
  const [formDebit] = Form.useForm();
  const [formDebitRetiree] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    axios
      .get(endpoints.prices.read)
      .then((res) => {
        console.log(res);
        setData(res.data[0]);
        setProfessions(res.data[1]);

        let filterMB = res.data[0].filter(
          (item) => item.type === "mb" && item.retiree === 0
        );
        let filterMBRetiree = res.data[0].filter(
          (item) => item.type === "mb" && item.retiree === 1
        );
        let filterMBWay = res.data[0].filter(
          (item) => item.type === "mbway" && item.retiree === 0
        );
        let filterMBWayRetiree = res.data[0].filter(
          (item) => item.type === "mbway" && item.retiree === 1
        );
        let filterDebit = res.data[0].filter(
          (item) => item.type === "debit" && item.retiree === 0
        );
        let filterDebitRetiree = res.data[0].filter(
          (item) => item.type === "debit" && item.retiree === 1
        );
        formMB.setFieldsValue({ price_table: filterMB });
        formMBRetiree.setFieldsValue({ price_table: filterMBRetiree });
        formMBWay.setFieldsValue({ price_table: filterMBWay });
        formMBWayRetiree.setFieldsValue({ price_table: filterMBWayRetiree });
        formDebit.setFieldsValue({ price_table: filterDebit });
        formDebitRetiree.setFieldsValue({ price_table: filterDebitRetiree });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleSubmit(values) {
    setIsSaving(true);
    axios
      .post(endpoints.prices.update, {
        data: values.price_table,
      })
      .then((res) => {
        console.log(res);

        axios.get(endpoints.purchase.updateDebits).then((res) => {
          console.log(res);
          setIsSaving(false);

          notification.success({
            message: `Sucesso`,
            description: "Dados foram gravados com sucesso!",
          });
        });
      })
      .catch((err) => {
        console.log(err);
        setIsSaving(false);
        notification.error({
          message: `Erro`,
          description: "Aconteceu algum erro! Por favor, tente novamente.",
        });
      });
  }

  return (
    <Row className="dash_card">
      <p className="settings_title">Quotas</p>
      <Divider className="settings_divider" />
      <Row>
        <Tabs
          id="tabs_admin"
          items={[
            {
              label: "MB",
              key: "MB",
              children: (
                <Tabs
                  id="tabs_retiree_mb"
                  items={[
                    {
                      label: "Profissional Ativo",
                      key: "active_professional_mb",
                      children: (
                        <Form
                          form={formMB}
                          id="settings-parecer-form"
                          layout="horizontal"
                          onFinish={handleSubmit}
                        >
                          <Row gutter={24}>
                            <Col span={12}>
                              <p className="text-center">Profissão</p>
                            </Col>
                            <Col span={12}>
                              <p className="text-center">Anual</p>
                            </Col>
                          </Row>
                          <Divider className="mt-10 mb-20" />
                          <Row gutter={24}>
                            <Form.List name="price_table">
                              {(fields, { add, remove }, index) => (
                                <Col span={24} className="form_list_prices">
                                  <Row
                                    key={index}
                                    gutter={[24, 12]}
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    {fields.map(
                                      ({ key, name, ...restField }, index) => (
                                        <>
                                          <Col span={12}>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "id_profession"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Selecione uma opção",
                                                },
                                              ]}
                                              className="w-100"
                                            >
                                              <Select
                                                size="large"
                                                disabled
                                                className="not_disabled"
                                                options={professions.map(
                                                  (item) => ({
                                                    value: item.id,
                                                    label: item.name,
                                                  })
                                                )}
                                              />
                                            </Form.Item>
                                            <Divider className="mt-20 mb-10" />
                                          </Col>
                                          <Col span={12}>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "price"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Selecione uma opção",
                                                },
                                              ]}
                                              className="w-100"
                                            >
                                              <InputNumber
                                                className="w-100"
                                                size="large"
                                              />
                                            </Form.Item>
                                            <Divider className="mt-20 mb-10" />
                                          </Col>
                                        </>
                                      )
                                    )}
                                  </Row>
                                </Col>
                              )}
                            </Form.List>
                          </Row>
                          <Row gutter={8}>
                            <Col span={24} align="middle">
                              <Button
                                onClick={formMB.submit}
                                className="mt-40"
                                size="large"
                                type="primary"
                                loading={isSaving}
                              >
                                Guardar alterações
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      ),
                    },
                    {
                      label: "Profissional Aposentado",
                      key: "retiree_professional_mb",
                      children: (
                        <Form
                          form={formMBRetiree}
                          id="settings-parecer-form"
                          layout="horizontal"
                          onFinish={handleSubmit}
                        >
                          <Row gutter={24}>
                            <Col span={12}>
                              <p className="text-center">Profissão</p>
                            </Col>
                            <Col span={12}>
                              <p className="text-center">Anual</p>
                            </Col>
                          </Row>
                          <Divider className="mt-10 mb-20" />
                          <Row gutter={24}>
                            <Form.List name="price_table">
                              {(fields, { add, remove }, index) => (
                                <Col span={24} className="form_list_prices">
                                  <Row
                                    key={index}
                                    gutter={[24, 12]}
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    {fields.map(
                                      ({ key, name, ...restField }, index) => (
                                        <>
                                          <Col span={12}>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "id_profession"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Selecione uma opção",
                                                },
                                              ]}
                                              className="w-100"
                                            >
                                              <Select
                                                size="large"
                                                disabled
                                                className="not_disabled"
                                                options={professions.map(
                                                  (item) => ({
                                                    value: item.id,
                                                    label: item.name,
                                                  })
                                                )}
                                              />
                                            </Form.Item>
                                            <Divider className="mt-20 mb-10" />
                                          </Col>
                                          <Col span={12}>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "price"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Selecione uma opção",
                                                },
                                              ]}
                                              className="w-100"
                                            >
                                              <InputNumber
                                                className="w-100"
                                                size="large"
                                              />
                                            </Form.Item>
                                            <Divider className="mt-20 mb-10" />
                                          </Col>
                                        </>
                                      )
                                    )}
                                  </Row>
                                </Col>
                              )}
                            </Form.List>
                          </Row>
                          <Row gutter={8}>
                            <Col span={24} align="middle">
                              <Button
                                onClick={formMBRetiree.submit}
                                className="mt-40"
                                size="large"
                                type="primary"
                                loading={isSaving}
                              >
                                Guardar alterações
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      ),
                    },
                  ]}
                />
              ),
            },
            {
              label: "MBWay",
              key: "MBWay",
              children: (
                <Tabs
                  id="tabs_retiree_mbway"
                  items={[
                    {
                      label: "Profissional Ativo",
                      key: "active_professional_mbway",
                      children: (
                        <Form
                          form={formMBWay}
                          id="settings-parecer-form"
                          layout="horizontal"
                          onFinish={handleSubmit}
                        >
                          <Row gutter={24}>
                            <Col span={12}>
                              <p className="text-center">Profissão</p>
                            </Col>
                            <Col span={12}>
                              <p className="text-center">Anual</p>
                            </Col>
                          </Row>
                          <Divider className="mt-10 mb-20" />
                          <Row gutter={24}>
                            <Form.List name="price_table">
                              {(fields, { add, remove }, index) => (
                                <Col span={24} className="form_list_prices">
                                  <Row
                                    key={index}
                                    gutter={[24, 12]}
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    {fields.map(
                                      ({ key, name, ...restField }, index) => (
                                        <>
                                          <Col span={12}>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "id_profession"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Selecione uma opção",
                                                },
                                              ]}
                                              className="w-100"
                                            >
                                              <Select
                                                size="large"
                                                disabled
                                                className="not_disabled"
                                                options={professions.map(
                                                  (item) => ({
                                                    value: item.id,
                                                    label: item.name,
                                                  })
                                                )}
                                              />
                                            </Form.Item>
                                            <Divider className="mt-20 mb-10" />
                                          </Col>
                                          <Col span={12}>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "price"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Selecione uma opção",
                                                },
                                              ]}
                                              className="w-100"
                                            >
                                              <InputNumber
                                                className="w-100"
                                                size="large"
                                              />
                                            </Form.Item>
                                            <Divider className="mt-20 mb-10" />
                                          </Col>
                                        </>
                                      )
                                    )}
                                  </Row>
                                </Col>
                              )}
                            </Form.List>
                          </Row>
                          <Row gutter={8}>
                            <Col span={24} align="middle">
                              <Button
                                onClick={formMBWay.submit}
                                className="mt-40"
                                size="large"
                                type="primary"
                                loading={isSaving}
                              >
                                Guardar alterações
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      ),
                    },
                    {
                      label: "Profissional Aposentado",
                      key: "retiree_professional_mbway",
                      children: (
                        <Form
                          form={formMBWayRetiree}
                          id="settings-parecer-form"
                          layout="horizontal"
                          onFinish={handleSubmit}
                        >
                          <Row gutter={24}>
                            <Col span={12}>
                              <p className="text-center">Profissão</p>
                            </Col>
                            <Col span={12}>
                              <p className="text-center">Anual</p>
                            </Col>
                          </Row>
                          <Divider className="mt-10 mb-20" />
                          <Row gutter={24}>
                            <Form.List name="price_table">
                              {(fields, { add, remove }, index) => (
                                <Col span={24} className="form_list_prices">
                                  <Row
                                    key={index}
                                    gutter={[24, 12]}
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    {fields.map(
                                      ({ key, name, ...restField }, index) => (
                                        <>
                                          <Col span={12}>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "id_profession"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Selecione uma opção",
                                                },
                                              ]}
                                              className="w-100"
                                            >
                                              <Select
                                                size="large"
                                                disabled
                                                className="not_disabled"
                                                options={professions.map(
                                                  (item) => ({
                                                    value: item.id,
                                                    label: item.name,
                                                  })
                                                )}
                                              />
                                            </Form.Item>
                                            <Divider className="mt-20 mb-10" />
                                          </Col>
                                          <Col span={12}>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "price"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Selecione uma opção",
                                                },
                                              ]}
                                              className="w-100"
                                            >
                                              <InputNumber
                                                className="w-100"
                                                size="large"
                                              />
                                            </Form.Item>
                                            <Divider className="mt-20 mb-10" />
                                          </Col>
                                        </>
                                      )
                                    )}
                                  </Row>
                                </Col>
                              )}
                            </Form.List>
                          </Row>
                          <Row gutter={8}>
                            <Col span={24} align="middle">
                              <Button
                                onClick={formMBWayRetiree.submit}
                                className="mt-40"
                                size="large"
                                type="primary"
                                loading={isSaving}
                              >
                                Guardar alterações
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      ),
                    },
                  ]}
                />
              ),
            },
            {
              label: "Débito direto",
              key: "debit",
              children: (
                <Tabs
                  id="tabs_retiree_debit"
                  items={[
                    {
                      label: "Profissional Ativo",
                      key: "active_professional_debit",
                      children: (
                        <Form
                          form={formDebit}
                          id="settings-parecer-form"
                          layout="horizontal"
                          onFinish={handleSubmit}
                        >
                          <Row gutter={24}>
                            <Col span={8}>
                              <p className="text-center">Profissão</p>
                            </Col>
                            <Col span={8}>
                              <p className="text-center">Anual</p>
                            </Col>
                            <Col span={8}>
                              <p className="text-center">Mensal</p>
                            </Col>
                          </Row>
                          <Divider className="mt-10 mb-20" />
                          <Row gutter={24}>
                            <Form.List name="price_table">
                              {(fields, { add, remove }, index) => (
                                <Col span={24} className="form_list_prices">
                                  <Row
                                    key={index}
                                    gutter={[24, 12]}
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    {fields.map(
                                      ({ key, name, ...restField }, index) => (
                                        <>
                                          {index % 2 === 0 ? (
                                            <Col span={16}>
                                              <Row gutter={[24]}>
                                                <Col span={12}>
                                                  <Form.Item
                                                    {...restField}
                                                    name={[
                                                      name,
                                                      "id_profession",
                                                    ]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Selecione uma opção",
                                                      },
                                                    ]}
                                                    className="w-100"
                                                  >
                                                    <Select
                                                      size="large"
                                                      disabled
                                                      className="not_disabled"
                                                      options={professions.map(
                                                        (item) => ({
                                                          value: item.id,
                                                          label: item.name,
                                                        })
                                                      )}
                                                    />
                                                  </Form.Item>
                                                  <Divider className="mt-20 mb-10" />
                                                </Col>
                                                <Col span={12}>
                                                  <Form.Item
                                                    {...restField}
                                                    name={[name, "price"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Selecione uma opção",
                                                      },
                                                    ]}
                                                    className="w-100"
                                                  >
                                                    <InputNumber
                                                      className="w-100"
                                                      size="large"
                                                    />
                                                  </Form.Item>
                                                  <Divider className="mt-20 mb-10" />
                                                </Col>
                                              </Row>
                                            </Col>
                                          ) : (
                                            <Col span={8}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, "price"]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Selecione uma opção",
                                                  },
                                                ]}
                                                className="w-100"
                                              >
                                                <InputNumber
                                                  className="w-100"
                                                  size="large"
                                                />
                                              </Form.Item>
                                              <Divider className="mt-20 mb-10" />
                                            </Col>
                                          )}
                                        </>
                                      )
                                    )}
                                  </Row>
                                </Col>
                              )}
                            </Form.List>
                          </Row>
                          <Row gutter={8}>
                            <Col span={24} align="middle">
                              <Button
                                onClick={formDebit.submit}
                                className="mt-40"
                                size="large"
                                type="primary"
                                loading={isSaving}
                              >
                                Guardar alterações
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      ),
                    },
                    {
                      label: "Profissional Aposentado",
                      key: "retiree_professional_debit",
                      children: (
                        <Form
                          form={formDebitRetiree}
                          id="settings-parecer-form"
                          layout="horizontal"
                          onFinish={handleSubmit}
                        >
                          <Row gutter={24}>
                            <Col span={8}>
                              <p className="text-center">Profissão</p>
                            </Col>
                            <Col span={8}>
                              <p className="text-center">Anual</p>
                            </Col>
                            <Col span={8}>
                              <p className="text-center">Mensal</p>
                            </Col>
                          </Row>
                          <Divider className="mt-10 mb-20" />
                          <Row gutter={24}>
                            <Form.List name="price_table">
                              {(fields, { add, remove }, index) => (
                                <Col span={24} className="form_list_prices">
                                  <Row
                                    key={index}
                                    gutter={[24, 12]}
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    {fields.map(
                                      ({ key, name, ...restField }, index) => (
                                        <>
                                          {index % 2 === 0 ? (
                                            <Col span={16}>
                                              <Row gutter={[24]}>
                                                <Col span={12}>
                                                  <Form.Item
                                                    {...restField}
                                                    name={[
                                                      name,
                                                      "id_profession",
                                                    ]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Selecione uma opção",
                                                      },
                                                    ]}
                                                    className="w-100"
                                                  >
                                                    <Select
                                                      size="large"
                                                      disabled
                                                      className="not_disabled"
                                                      options={professions.map(
                                                        (item) => ({
                                                          value: item.id,
                                                          label: item.name,
                                                        })
                                                      )}
                                                    />
                                                  </Form.Item>
                                                  <Divider className="mt-20 mb-10" />
                                                </Col>
                                                <Col span={12}>
                                                  <Form.Item
                                                    {...restField}
                                                    name={[name, "price"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Selecione uma opção",
                                                      },
                                                    ]}
                                                    className="w-100"
                                                  >
                                                    <InputNumber
                                                      className="w-100"
                                                      size="large"
                                                    />
                                                  </Form.Item>
                                                  <Divider className="mt-20 mb-10" />
                                                </Col>
                                              </Row>
                                            </Col>
                                          ) : (
                                            <Col span={8}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, "price"]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Selecione uma opção",
                                                  },
                                                ]}
                                                className="w-100"
                                              >
                                                <InputNumber
                                                  className="w-100"
                                                  size="large"
                                                />
                                              </Form.Item>
                                              <Divider className="mt-20 mb-10" />
                                            </Col>
                                          )}
                                        </>
                                      )
                                    )}
                                  </Row>
                                </Col>
                              )}
                            </Form.List>
                          </Row>
                          <Row gutter={8}>
                            <Col span={24} align="middle">
                              <Button
                                onClick={formDebitRetiree.submit}
                                className="mt-40"
                                size="large"
                                type="primary"
                                loading={isSaving}
                              >
                                Guardar alterações
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      ),
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </Row>
    </Row>
  );
}
