import { useEffect, useState } from "react";
import { Col, Row, Input, Table, Button, Space, DatePicker } from "antd";
import axios from 'axios';
import moment from 'moment';
import { SearchOutlined, SettingOutlined, LinkOutlined, EyeOutlined, ReloadOutlined, CheckCircleOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';

import endpoints from "../../../../utils/endoinpts";
import './List.css';
import AdminViewPayment from "../View/View";
import DrawerExportTable from "../../Export/DrawerExportTable";


const { RangePicker } = DatePicker;

const columnsTable = [
  {
    title: 'Sócio',
    dataIndex: 'n_member',
    sorter: (a, b) => {
      let a_n_member = a.n_member.props.children ? Number(a.n_member.props.children) : null;
      let b_n_member = b.n_member.props.children ? Number(b.n_member.props.children) : null;
      return a_n_member - b_n_member
    }
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    width: 250,
    sorter: (a, b) => a.name.toString().localeCompare(b.name),
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    width: 250,
    sorter: (a, b) => a.email.toString().localeCompare(b.email)
  },
  {
    title: 'Tipo',
    dataIndex: 'type',
    width: 100,
    filters: [
      {
        text: 'Quotas',
        value: 'Quotas'
      },
      {
        text: 'Eventos',
        value: 'Eventos'
      }

    ],
    onFilter: (value, record) => record.type === value,
  },
  {
    title: 'Pagamento por',
    dataIndex: 'payment_by',
    width: 180,
    filters: [
      {
        text: 'Icentivos instituicionais',
        value: 'Icentivos instituicionais'
      },
      {
        text: 'O(a) próprio(a)',
        value: 'O(a) próprio(a)'
      }

    ],
    onFilter: (value, record) => record.payment_plan === value,
  },
  {
    title: 'Método',
    dataIndex: 'method',
    width: 120,
    filters: [
      {
        text: 'Débito direto',
        value: 'Débito direto'
      },
      {
        text: 'MBWay',
        value: 'MBWay'
      },
      {
        text: 'Multibanco',
        value: 'Multibanco'
      }
    ],
    onFilter: (value, record) => record.method === value,
  },
  {
    title: 'ID Pagamento',
    dataIndex: 'payment_id',
    width: 120,
  },
  {
    title: 'ID Processo',
    dataIndex: 'process_id',
    width: 200,
  },
  {
    title: 'Estado',
    dataIndex: 'status',
    filters: [
      {
        text: 'Pago',
        value: 'pago'
      },
      {
        text: 'Em validação',
        value: 'em validação'
      },
      {
        text: 'Próximo pagamento',
        value: 'próximo pagamento'
      },
      {
        text: 'Cancelado',
        value: 'cancelado'
      }
    ],
    onFilter: (value, record) => record.status === value,
  },
  {
    title: 'Data',
    dataIndex: 'date',
    width: 140,
    sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix()
  },
  {
    title: 'Data de pagamento',
    dataIndex: 'payment_date',
    width: 140,
    sorter: (a, b) => dayjs(a.payment_date).unix() - dayjs(b.payment_date).unix()
  },
  {
    title: 'Valor',
    dataIndex: 'value',
    width: 120
  },
  {
    title: 'Voucher',
    dataIndex: 'voucher',
    width: 120
  },
  {
    title: '',
    dataIndex: 'link',
  },
  {
    title: '',
    dataIndex: 'validate',
  },
  {
    title: '',
    dataIndex: 'view',
  },
];

export default function List() {

  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [payments, setPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [dates, setDates] = useState(null);

  useEffect(() => {
    handleGetAdminInfo()
  }, [])

  function handleGetAdminInfo() {
    setIsLoading(true);
    axios.get(endpoints.payment.getPayments).then((res) => {
      setPayments(res.data);
      handlePrepareTableData(res.data);
    }).catch((err) => {
      console.log(err);
    })
  }

  function handlePrepareTableData(data) {
    let auxTable = [];

    for (let i = 0; i < data.length; i++) {
      let auxData = data[i];
      let aux = {
        key: auxData.id,
        id: auxData.id,
        n_member: auxData.id_guest ? <div className="n_member_table">NS</div> : (<div className="n_member_table">{auxData.n_member}</div>),
        name: auxData.id_guest ? auxData.guest_name : auxData.name,
        email:auxData.id_guest ? auxData.guest_email : auxData.email,
        type: auxData.type === "quotes" ? "Quotas" : "Eventos",
        payment_by: auxData.payment_by,
        method: auxData.method === "debit" ? "Débito direto" : (auxData.method === "mb" ? "Multibanco" : (auxData.method === "mbway" ? "MBWay" : null)),
        payment_id: auxData.payment_id,
        process_id: auxData.process_id,
        status: auxData.status,
        payment_date: auxData.payment_date ? moment(auxData.payment_date).format('YYYY-MM-DD') : null,
        date: auxData.payment_date ? moment(auxData.date).format('YYYY-MM-DD') : null,
        value: auxData.value + '€',
        voucher: auxData.voucher_name,
        link: auxData.permalink ? (<Button target="_blank" href={auxData.permalink}><LinkOutlined /></Button>) : '-',
        view: auxData.method === 'mb' ? (<Button onClick={() => handleOpenViewPayment(auxData)}><EyeOutlined /></Button>) : '',
        validate: (auxData.payment_by === 'Icentivos instituicionais' && auxData.status !== 'pago') ?
          (<Button onClick={() => handleValidatePayment(auxData)}><CheckCircleOutlined /></Button>) : '',
        full_data: auxData
      }

      auxTable.push(aux);
    }

    setTableData(auxTable);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  function handleValidatePayment(item) {
    axios.post(endpoints.purchase.verifyPurchaseManually, {
      data: item
    }).then((res) => {
      handleGetAdminInfo();
    }).catch((err) => {
      console.log(err);
    })
  }

  function handleSearchPayments(e, rangeDate) {
    setIsLoading(true);
    let searchString = typeof e === 'string' ? searchText : e.target.value;
    setSearchText(searchString);
    let auxDates = rangeDate;
    let paymentsFiltered = JSON.parse(JSON.stringify(payments));

    if (auxDates && auxDates[0] && auxDates[1]) {
      let auxPaymentsFiltered = payments.filter((value, index) => {
        return dayjs(auxDates[0]).diff(value.payment_date, 'day') <= 0 && dayjs(auxDates[1]).diff(value.payment_date, 'day') >= 0
      });

      paymentsFiltered = auxPaymentsFiltered.filter((value, index) => {
        let payment_id = value.payment_id ? value.payment_id : "";
        let n_member = value.n_member ? value.n_member.toString() : "";
        let process_id = value.process_id ? value.process_id.toString() : "";
        return payment_id.includes(searchString) || n_member.includes(searchString) || value.name.includes(searchString)
          || value.email.includes(searchString) || process_id.includes(searchString)
      });
    } else {
      paymentsFiltered = payments.filter((value, index) => {
        let payment_id = value.payment_id ? value.payment_id : "";
        let n_member = value.n_member ? value.n_member.toString() : "";
        let process_id = value.process_id ? value.process_id.toString() : "";
        let name = value.name ? value.name : ""
        let email = value.email ? value.email : ""

        return payment_id.includes(searchString) || n_member.includes(searchString) || name.includes(searchString)
          || email.includes(searchString) || process_id.includes(searchString)
      });
    }

    handlePrepareTableData(paymentsFiltered);
    setIsLoading(false);
  }

  function handleOpenViewPayment(data) {
    setSelectedPayment(data);
    setIsOpenView(true);
  }

  function handleCloseViewPayment() {
    setSelectedPayment({});
    setIsOpenView(false);
  }

  function handleCloseExport() {
    setIsOpenExport(false);
  }

  function handleChangeDate(e) {
    setDates(e);
    handleSearchPayments(searchText, e);
  }

  return (
    <Row id='admin-list-users' className='dash_card'>
      <DrawerExportTable open={isOpenExport} close={handleCloseExport} data={tableData} type={'Pagamentos'} />
      <AdminViewPayment data={selectedPayment} open={isOpenView} close={handleCloseViewPayment} />
      <Col span={24} className="p-20">
        <Row className='ml-0 mr-0'>
          <Col span={8} className="d-flex align-items-center">
            <p className="title m-0">Pagamentos</p>
          </Col>
          <Col span={16} className="d-flex jc-end">
            <Row className="d-flex flex-nowrap w-100">
              <RangePicker
                onCalendarChange={handleChangeDate}
                className="w-100 mr-10"
              />
              <Button size="large" className='mr-10' onClick={handleGetAdminInfo}>
                <ReloadOutlined />
              </Button>
              <Button size="large" className='mr-10' onClick={() => setIsOpenExport(true)}>
                Exportar Tabela
              </Button>
              <Input
                allowClear
                type="text"
                size="large"
                onChange={(e) => handleSearchPayments(e, dates)}
                className="search_users"
                placeholder="Procurar pagamentos"
                suffix={<SearchOutlined />}
                value={searchText}
              />
            </Row>
          </Col>
        </Row>
        <Row className="mt-20">
          <Col span={24}>
            <Table
              scroll={{ x: 2000 }}
              columns={columnsTable}
              dataSource={tableData}
              loading={isLoading}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}