const columns = [
  { dataIndex: "id", title: "Id" },
  { dataIndex: "name", title: "Nome" },
  { dataIndex: "img", title: "Imagem" },
  { dataIndex: "image", title: "Imagem" },
  { dataIndex: "email", title: "E-mail" },
  { dataIndex: "gender", title: "Sexo" },
  { dataIndex: "birth_date", title: "Data de nascimento" },
  { dataIndex: "phone", title: "Telefone" },
  { dataIndex: "cc", title: "CC" },
  { dataIndex: "nif", title: "NIF" },
  { dataIndex: "address", title: "Morada" },
  { dataIndex: "post_code", title: "Código Postal" },
  { dataIndex: "country", title: "País" },
  { dataIndex: "district", title: "Distrito" },
  { dataIndex: "county", title: "Concelho" },
  { dataIndex: "parish", title: "Freguesia" },
  { dataIndex: "professional_name", title: "Nome professional" },
  { dataIndex: "county", title: "Concelho" },
  { dataIndex: "parish", title: "Freguesia" },
  { dataIndex: "boarding_year", title: "Primeiro ano de internato" },
  { dataIndex: "work_district", title: "Distrito de trabalho" },
  { dataIndex: "n_member", title: "Nº de associado" },
  { dataIndex: "quotes_regularization", title: "Reg. Quotas" },
  { dataIndex: "rgpd", title: "RGPD" },
  { dataIndex: "payment_method", title: "Método de pagamento" },
  { dataIndex: "payment_valid", title: "Pagamento válido" },
  { dataIndex: "payment_date", title: "Data de pagamento" },
  { dataIndex: "iban", title: "IBAN" },
  { dataIndex: "bank_name", title: "Nome do banco" },
  { dataIndex: "bank_user_name", title: "Nome da conta do banco" },
  { dataIndex: "type", title: "Tipo" },
  { dataIndex: "created_at", title: "Data de registo" },
  { dataIndex: "payment_plan_type", title: "Tipo do plano de pagamento" },
  { dataIndex: "profession_name", title: "Profissão" },
  { dataIndex: "ars_name", title: "ARS" },
  { dataIndex: "aces_name", title: "ACeS" },
  { dataIndex: "usf_name", title: "Unidade" },
  { dataIndex: "profession", title: "Profissão" },
  { dataIndex: "enroll_date", title: "Data de compra" },
  { dataIndex: "payment_status", title: "Estado do pagamento" },
  { dataIndex: "invoice_items", title: "Items de faturação" },
  { dataIndex: "enroll_date", title: "Data de compra" },
  { dataIndex: "status", title: "Estado" },
  { dataIndex: "is_deleted", title: "Apagado" },
];

export default columns;
