import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Row, Form, Drawer, Upload, Button, Spin, Switch, Table, InputNumber, Divider } from "antd";
import { CheckCircleOutlined, EditOutlined, InboxOutlined } from '@ant-design/icons'
import axios from "axios";

import endpoints from '../../../../utils/endoinpts';
import config from "../../../../utils/config";
import './Import.css';

const { Dragger } = Upload;


export default function Import({ open, close }) {

  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [usersToImport, setUsersToImport] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [results, setResults] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const uploadProps = {
    accept: '.csv',
    name: 'file',
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setTableColumns([]);
      setTableData([]);
      setUsersToImport([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      handleReadFile(file);
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    fileList,
    defaultFileList: []
  };

  useEffect(() => {
    setIsLoading(false);
  }, [open]);

  function handleReadFile(file) {
    setIsLoading(true);
    const formData = new FormData();

    formData.append('file', file);

    fetch(`${config.server_ip}${endpoints.file.readFileImportUsers}`, {
      method: "POST",
      body: formData
    }).then((res) => {
      res.json().then((responseData) => {

        let auxTableData = [];

        for (let i = 0; i < responseData.length; i++) {
          auxTableData.push(responseData[i]);
        }

        let tableColumnsArray = Object.keys(responseData[0]);
        let auxTableColumns = [];

        for (let z = 0; z < tableColumnsArray.length; z++) {
          auxTableColumns.push({
            title: tableColumnsArray[z],
            dataIndex: tableColumnsArray[z],
            key: z,
            width: '50%'
          })
        }

        setTableData(auxTableData);
        setTableColumns(auxTableColumns);
        setUsersToImport(responseData);
        setIsLoading(false);
      }).catch(e => {
        console.log(e);
        setIsLoading(false);
      })
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
    });
  }

  async function handleSubmit(values) {
    setIsLoading(true);
    setIsButtonLoading(true);

    let inserted_users = [];
    let updated_users = [];
    let email_success = [];
    let email_error = [];
    let total_emails = 0;
    let limit_bulk = values.limit_bulk ? values.limit_bulk : usersToImport.length;
    let send_email = values.send_email ? true : false;
    let auxUsersToImport = usersToImport.slice(0, limit_bulk);
    let count = 0;

    handleManageImportData(auxUsersToImport, send_email, limit_bulk, count, inserted_users, updated_users, email_success, email_error, total_emails).then((res) => {
      let auxTableData = [];
      let auxTableUserData = [];
      for (let i = 0; i < res.email_success.length; i++) {
        auxTableData.push({ ...res.email_success[i], status: 'success' })
      }

      for (let i = 0; i < res.email_error.length; i++) {
        auxTableData.push({ ...res.email_error[i], status: 'error' })
      }

      for (let i = 0; i < res.inserted_users.length; i++) {
        auxTableUserData.push({ ...res.inserted_users[i], status: 'inserted' })
      }

      for (let i = 0; i < res.updated_users.length; i++) {
        auxTableUserData.push({ ...res.updated_users[i], status: 'updated' })
      }

      let aux = {
        data: auxTableData,
        userData: auxTableUserData,
        inserted_users: res.inserted_users,
        updated_users: res.updated_users,
        email_success: res.email_success,
        email_error: res.email_error,
        total_emails: res.total_emails,
      }

      setShowResult(true);
      setResults(aux);
      setIsLoading(false);
      setIsButtonLoading(false);
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
      setIsButtonLoading(false);
    })
  }

  async function handleManageImportData(data, send_email, limit_bulk, count, inserted_users, updated_users, email_success, email_error, total_emails) {
    return handleImport(data, send_email).then((importedData) => {
      console.log(importedData);
      console.log(email_error);
      inserted_users.push(...importedData.data.inserted_users);
      updated_users.push(...importedData.data.updated_users);
      email_success.push(...importedData.data.email_success);
      email_error.push(...importedData.data.email_error);
      total_emails += importedData.data.total_emails;

      count++;
      let auxUsersToImport = usersToImport.slice((count * limit_bulk), ((limit_bulk * count) + limit_bulk));

      if (auxUsersToImport.length > 0) {
        return handleManageImportData(auxUsersToImport, send_email, limit_bulk, count, inserted_users, updated_users, email_success, email_error, total_emails);
      } else {
        return { total_users: usersToImport.length, inserted_users, updated_users, email_success, email_error, total_emails };
      }
    })
  }

  function handleImport(e, send_email) {
    return new Promise((resolve, reject) => {
      axios.post(endpoints.file.importUsers, {
        data: { users: e, send_email: send_email }
      }).then((res) => {
        resolve(res);
      }).catch((error) => {
        reject(error)
      })
    })
  }

  function handleClose() {
    setShowResult(false);
    setFileList([]);
    setTableColumns([]);
    setTableData([]);
    setUsersToImport([]);
    close();
  }

  return (
    <Drawer
      title={`Importar sócios`}
      width={1400}
      onClose={handleClose}
      open={open}
      bodyStyle={{ paddingBottom: 80 }}
      maskClosable={false}
    >
      <Spin spinning={isLoading} tip="Uploading...">
        {showResult ?
          <Col span={24}>
            <p className="f-24 bold text-center">Nº total de utilizadores</p>
            <Row gutter={[24]} className="mt-30">
              <Col span={24}>
                <p className="f-60 bold text-center m-0">{usersToImport?.length}</p>
              </Col>
            </Row>
            <Divider />
            <p className="f-24 bold text-center">Utilizadores importados com sucesso!</p>
            <Row gutter={[24]} className="mt-30">
              <Col span={12}>
                <p className="f-20 text-center">Inseridos:</p>
                <p className="f-24 bold text-center">{results.inserted_users?.length}</p>
              </Col>
              <Col span={12}>
                <p className="f-20 text-center">Atualizados:</p>
                <p className="f-24 bold text-center">{results.updated_users?.length}</p>
              </Col>
            </Row>
            <Row>
              <Table
                className="mt-40 w-100"
                columns={[
                  {
                    title: 'Nome',
                    dataIndex: 'name',
                  },
                  {
                    title: 'E-mail',
                    dataIndex: 'email',
                  },
                  {
                    title: 'Status',
                    dataIndex: 'status',
                    filters: [
                      {
                        text: 'Atualizado',
                        value: 'updated',
                      },
                      {
                        text: 'Inserido',
                        value: 'inserted',
                      },
                    ],
                    onFilter: (value, record) => results.userData.filter(item => item.status === value)[0],
                  },
                ]}
                dataSource={results.userData?.map((item) => {
                  return {
                    name: item.name,
                    email: item.email,
                    status: item.status === 'inserted' ? <CheckCircleOutlined className="green f-16" /> : <EditOutlined className="red f-16" />
                  }
                })}
                loading={isTableLoading}
              />
            </Row>
            <Divider />
            {form.getFieldValue('send_email') &&
              <>
                <p className="f-24 bold text-center">E-mails enviados com sucesso!</p>
                <Row gutter={[24]} className="mt-30">
                  <Col span={12}>
                    <p className="f-20 text-center">Sucesso:</p>
                    <p className="f-24 bold text-center">{results.email_success?.length}</p>
                  </Col>
                  <Col span={12}>
                    <p className="f-20 text-center">Erro:</p>
                    <p className="f-24 bold text-center">{results.email_error?.length}</p>
                  </Col>
                </Row>
                <Row>
                  <Table
                    className="mt-40 w-100"
                    columns={[
                      {
                        title: 'Nome',
                        dataIndex: 'name',
                      },
                      {
                        title: 'E-mail',
                        dataIndex: 'email',
                      },
                      {
                        title: 'Status',
                        dataIndex: 'status',
                        filters: [
                          {
                            text: 'Sucesso',
                            value: 'success',
                          },
                          {
                            text: 'Erro',
                            value: 'error',
                          },
                        ],
                        onFilter: (value, record) => results.data.filter(item => item.status === value)[0],
                      },
                    ]}
                    dataSource={results.data?.map((item) => {
                      return {
                        name: item.name,
                        email: item.email,
                        status: item.status === 'success' ? <CheckCircleOutlined className="green f-16" /> : <CloseCircleOutlined className="red f-16" />
                      }
                    })}
                    loading={isTableLoading}
                  />
                </Row>
              </>
            }
          </Col>
          :
          <>
            <Dragger {...uploadProps} style={{ maxHeight: 200 }} className="import_users_dragger">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                banned files.
              </p>
            </Dragger>
            {usersToImport.length > 0 &&
              <Row className="import_users_info">
                <Col span={24}>
                  <div className="title">Nº de utilizadores a importar:</div>
                  <div className="number">{usersToImport.length}</div>
                  <Row>
                    <Col span={24}>
                      <Form
                        form={form}
                        id="form-import-users"
                        onFinish={handleSubmit}
                        className='form'
                        autoComplete="off"
                        layout="vertical"
                      >
                        <Row>
                          <Col span={24}>
                            <Table
                              className="import_users_table"
                              columns={tableColumns}
                              dataSource={tableData}
                              loading={isTableLoading}
                              scroll={{ x: 1 }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item label="Send e-mail" name="send_email" valuePropName="checked">
                              <Switch size="large" />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item label="Limit bulk" name="limit_bulk">
                              <InputNumber className="w-100" size="large" placeholder="0" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Button size="large" type="primary" onClick={form.submit} loading={isButtonLoading}>
                            Importar utilizadores
                          </Button>
                        </Row>
                      </Form>

                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          </>
        }
      </Spin>
    </Drawer>
  );
}
