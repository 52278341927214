import { Col, Form, Row, Input, Select } from "antd";
import geoAPI from "../../../utils/districts";

import './Register.css';
import { useState } from "react";

export default function RegisterStepTwo({ form, handleSubmit, handleSubmitFailed }) {

  const [districts] = useState(geoAPI.districts);
  const [counties, setCounties] = useState([]);
  const [parishes, setParishes] = useState([]);

  function handleChangeDistrict(e) {
    form.setFieldValue('county', null);
    form.setFieldValue('parish', null);
    let auxCounties = districts.filter((item) => item.name === e)[0];
    setCounties(auxCounties.county);
  }

  function handleChangeCounty(e) {
    form.setFieldValue('parish', null);
    let auxParishes = geoAPI.parishes.filter((item) => item.nome === e)[0];
    setParishes(auxParishes.freguesias);
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      onFinishFailed={handleSubmitFailed}
    >
    <Row className="d-flex jc-center ai-center">
        <Col xs={24} sm={20} md={20} lg={20} xl={20}>
          <Form.Item
            name="address"
            label="Morada (residência)"
            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
          >
            <Input
              type="text"
              size="large"
              placeholder="Morada"
            />
          </Form.Item>
          <Form.Item
            name="post_code"
            label="Código-Postal"
            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
            autoComplete="off"
          >
            <Input
              type="text"
              size="large"
              placeholder="Código-Postal"
            />
          </Form.Item>
          <Form.Item
            name="district"
            label="Distrito"
            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
          >
            <Select
              size="large"
              showSearch
              placeholder="Distrito"
              optionFilterProp="children"
              autoComplete="none"
              onChange={handleChangeDistrict}
              filterOption={(input, option) =>
                (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
              }
              getPopupContainer={trigger => trigger.parentNode}
              options={districts.map((item) => (
                { value: item.name, label: item.name }
              ))}
            />
          </Form.Item>
          <Form.Item
            name="county"
            label="Concelho"
            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
          >
            <Select
              size="large"
              showSearch
              placeholder="Concelho"
              autoComplete="none"
              onChange={handleChangeCounty}
              filterOption={(input, option) =>
                (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
              }
              getPopupContainer={trigger => trigger.parentNode}
              options={counties.map((item) => (
                { value: item.name, label: item.name }
              ))}
            />
          </Form.Item>
          <Form.Item
            name="parish"
            label="Localidade"
            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
          >
            <Select
              size="large"
              showSearch
              placeholder="Localidade"
              autoComplete="none"
              filterOption={(input, option) =>
                (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
              }
              getPopupContainer={trigger => trigger.parentNode}
              options={parishes.map((item) => (
                { value: item, label: item }
              ))}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
