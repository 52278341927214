import React, { useState, useEffect } from 'react'
import { Col, Row, Space, Input, Table, Avatar, Tooltip, Modal, Button } from 'antd'
import { DeleteOutlined, SearchOutlined, EditOutlined, ReloadOutlined, LinkOutlined } from "@ant-design/icons";
import axios from 'axios';
import dayjs from 'dayjs';

import endpoints from '../../../../utils/endoinpts'
import config from '../../../../utils/config';

const { Search } = Input

const columns = [
  {
    title: 'Sócio',
    dataIndex: 'n_member',
    sorter: (a, b) => {
      let a_n_member = a.n_member.props.children ? Number(a.n_member.props.children) : null;
      let b_n_member = b.n_member.props.children ? Number(b.n_member.props.children) : null;
      return a_n_member - b_n_member
    }
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    sorter: (a, b) => a.name.toString().localeCompare(b.name),
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    sorter: (a, b) => a.email.toString().localeCompare(b.email),
  },
  {
    title: 'Telemóvel',
    dataIndex: 'phone',
  },
  {
    title: 'Tema',
    dataIndex: 'theme',
  },
  {
    title: 'Ficheiros',
    dataIndex: 'files_length',
  },
  {
    title: '',
    dataIndex: 'files_names',
  },
  {
    title: 'Data',
    dataIndex: 'created_at',
    width: 160,
  },
];

export default function List() {

  const [isLoading, setIsLoading] = useState(true);
  const [forms, setForms] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    handleGetData()
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios.get(endpoints.app.getFormDepartment).then((res) => {
      setForms(res.data);
      handlePrepareData(res.data);
    }).catch((error) => {
      console.log(error)
    });
  }

  /* very important */
  function handlePrepareData(data) {
    let newArrayItens = [];

    data.map((item, index) => {

      newArrayItens.push({
        key: item.id,
        n_member: (<div className="n_member_table">{item.n_member}</div>),
        name: item.n_member,
        name: item.name,
        email: item.email,
        phone: item.phone,
        theme: item.theme,
        files_length: JSON.parse(item.files).length,
        files_names: JSON.parse(item.files).length > 0 ? <Button onClick={() => handleOpenFile(item.files_names)}><LinkOutlined /></Button> : null,
        created_at: dayjs(item.created_at).format('YYYY-MM-DD HH:mm'),
        full_data: item,
      })
    })
    setTableData(newArrayItens);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  /* search bar action */
  function handleSearch(e) {
    setIsLoading(true);
    setSearchText(e.target.value);

    let dataFiltered = forms.filter((value, index) => {
      return value.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        value.email.toLowerCase().includes(e.target.value.toLowerCase()) || value.theme.toLowerCase().includes(e.target.value.toLowerCase())
        || value.n_member?.toString().toLowerCase().includes(e.target.value.toLowerCase())
    });

    handlePrepareData(dataFiltered);
    setIsLoading(false);
  }

  function handleOpenFile(files) {
    for (let i = 0; i < files.length; i++) {
      window.open(`${config.server_ip}files/${files[i]}`)
    }
  }


  return (
    <Row id='admin-list-users' className='dash_card'>
      <Col span={24} className='p-20'>
        <Row className='ml-0 mr-0'>
          <Col span={16} className='d-flex align-items-center'>
            <p className='title bold m-0'>Formulário Departamento</p>
          </Col>
          <Col span={8} className='d-flex jc-end'>
            <Row className="d-flex flex-nowrap w-100">
              <Button size='large' className='mr-10' onClick={handleGetData}>
                <ReloadOutlined />
              </Button>
              <Input
                allowClear
                type="text"
                size="large"
                onChange={handleSearch}
                className="search_users"
                placeholder="Procurar dados"
                suffix={<SearchOutlined />}
                value={searchText}
              />
            </Row>
          </Col>
        </Row>
        <Row className='ml-0 mr-0 mt-20'>
          <Col span={24}>
            <Table
              loading={isLoading}
              className='table'
              dataSource={tableData}
              columns={columns}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
