import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { Col, Form, Row, notification, Input, Button } from "antd";
import axios from 'axios';
import logoUSF from '../../../assets/images/USF-AN.svg';
import logoUSFBackground from '../../../assets/images/logo-usg-login-criar-conta.svg';
import config from '../../../utils/config';
import endpoints from '../../../utils/endoinpts';

import './ResetPassword.css';

function ResetPassword() {

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  let tokenPassword = searchParams.get("token");
  let emailPassword = searchParams.get("email");

  const [formPassword] = Form.useForm();
  const navigate = useNavigate();

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: 'Faltam alguns campos para preencher!'
    });
  }
  function handleSubmit() {
    setIsButtonLoading(true);
    let values = formPassword.getFieldsValue(true);

    let data = {
      token: tokenPassword,
      email: emailPassword,
      password: values.password
    }

    axios.post(`${config.server_ip}${endpoints.auth.resetPassword}`, {
      data
    }).then((res) => {
      setIsButtonLoading(false);
      notification.success({
        message: 'Password alterada',
        description:
          'A sua password foi alterada com sucesso!',
      });
      navigate('/login');
    }).catch((err) => {
      console.log(err);
      if (!err.token_valid) {
        notification.error({
          message: 'Erro',
          description:
            'Este link não é válido. Por favor verifique se o link é o mesmo que enviamos para o seu e-mail.',
        });
      }
      setIsButtonLoading(false);
    })
  }

  return (
    <Row className="d-flex jc-center ai-center">
      <Col xs={24} sm={24} md={13} lg={13} xl={13}>
        <Row className="form-register-content">
          <Col span={24}>
            <h1 className="text-center mb-0" style={{ color: '#4A4C4D' }}>Nova password</h1>
            <p className="text-center" style={{ marginTop: '5px', color: '#BDBCBC' }}>Preencha abaixo os campos com a sua nova password</p>
            <Form
              form={formPassword}
              id="login-form-step-one"
              className="form-login"
              layout="vertical"
              onFinish={handleSubmit}
              onFinishFailed={handleWarningMissingFields}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                    hasFeedback
                  >
                    <Input.Password size="large" placeholder="Password" />
                  </Form.Item>
                  <Form.Item
                    name="confirm"
                    label="Confirmar Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Confirme a sua password, por favor!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('As passwords não correspondem'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password size="large" placeholder="Confirmar Password" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row className="form-login-buttons mt-0">
          <Col span={24} className={"d-flex flex-column align-items-center justify-content-center"}>
            <Button
              loading={isButtonLoading}
              className="button-login"
              onClick={() => formPassword.submit()}
            >
              Reset Password
            </Button>
            <p className="register-text">Ainda não tenho conta. <a onClick={() => navigate('/register')}>Registe-se aqui</a></p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ResetPassword;
