import { useEffect, useState } from "react";
import { Col, Modal, Row, Button, Spin, Form, Checkbox } from "antd";
import axios from "axios";

import endpoints from "../../../utils/endoinpts";
import "./ModalRegularization.css";
import config from "../../../utils/config";

function QuotesRegularization({ user, show, close }) {
  const [configText, setConfigText] = useState("");
  const [configTitle, setConfigTitle] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [formQuotesRegularization] = Form.useForm();

  useEffect(() => {
    axios
      .get(endpoints.info.getSingleConfig, {
        params: { type: "quotes_regularization" },
      })
      .then((res) => {
        if (res.data.length > 0) {
          let data = res.data[0];
          setConfigText(data.text);
          setConfigTitle(data.title);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleSubmit() {
    setIsButtonLoading(true);
    let userData = user;
    userData.quotes_regularization = 1;
    axios
      .post(endpoints.app.updateUserRegularization, {
        data: userData,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        setTimeout(() => {
          close();
          setIsButtonLoading(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Modal
        className="payment_options"
        open={show}
        onCancel={close}
        closable={false}
        footer={[
          <div className="quotes_regularization_footer">
            <Button
              className="regularization_button"
              key="submit"
              type="primary"
              onClick={() => formQuotesRegularization.submit()}
              loading={isButtonLoading}
            >
              Aceitar
            </Button>
          </div>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form
            form={formQuotesRegularization}
            id="quotes-regularization"
            layout="vertical"
            onFinish={handleSubmit}
          >
            <Row gutter={24}>
              <Col span={24}>
                <b>{configTitle}</b>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <div dangerouslySetInnerHTML={{ __html: configText }}></div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  className="mb-0"
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Este campo é obrigatório")
                            ),
                    },
                  ]}
                >
                  <Checkbox>Eu li e concordo com as condições</Checkbox>
                </Form.Item>
                <Form.Item
                  name="rgpd"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Este campo é obrigatório")
                            ),
                    },
                  ]}
                >
                  <Checkbox>
                    No âmbito do Regime Geral de Proteção de Dados, aceito o
                    tratamento dos meus dados pessoais de associado da USF-AN
                    tal como descrito no{" "}
                    <a
                      onClick={() =>
                        window.open(
                          `${config.server_ip}files/Regulamento-Politica-de-Privacidade-e-proteccao-de-dados-da-USF-AN-2023.pdf`
                        )
                      }
                    >
                      documento
                    </a>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}

export default QuotesRegularization;
