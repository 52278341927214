import { useEffect, useState } from "react";
import { Col, Modal, Row, Button, Spin, Form, Checkbox } from "antd";
import axios from 'axios';

import endpoints from "../../../utils/endoinpts";
import './ModalUserCancelPayment.css';
import config from "../../../utils/config";

function ModalUserCancelPayment({ user, open, close, handleSubmit }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  return (
    <>
      <Modal
        title="Cancelamento do pagamento da subscrição"
        className="modal_cancel"
        open={open}
        onCancel={close}
        footer={[
          <div className="quotes_regularization_footer">
            <Button
              size="large"
              onClick={close}
              disabled={isButtonLoading}
            >
              Não
            </Button>
            <Button
              danger
              size="large"
              onClick={handleSubmit}
              loading={isButtonLoading}
            >
              Sim
            </Button>
          </div>
        ]}
      >
        <div className="d-flex flex-column">
          <p>Tem a certeza que quer cancelar o pagamento?</p>
          <p>Se cancelar este pagamento deixa de ter acesso às funcionalidades da plataforma. Para subscrever um novo pagamento terá de efetuar os mesmos passos novamente</p>
        </div>
      </Modal>
    </>
  );
}

export default ModalUserCancelPayment;
