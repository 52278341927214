import { message } from 'antd';
import React, { useEffect } from 'react';
import {
  Outlet,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

export default function AdminAuth() {

  const { user } = useOutletContext();
  const { handleUpdateUser } = useOutletContext();

  const navigate = useNavigate()

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      if (!user.is_admin && !user.is_viewer) {
        message.error({ content: 'Não tem acesso ao dashboard de admin!' })
        navigate('/');
      }
    }
  }, [user]);

  return (
    <Outlet context={{ user, handleUpdateUser }} />
  );
}