import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import dayjs from "dayjs";
import userDefault from "../../assets/images/user-default.svg";
import "./UserBar.css";
import config from "../../utils/config";

function UserBar({ user }) {
  const location = useLocation();

  const navigate = useNavigate();

  return (
    <>
      {location.pathname !== "/profile" &&
        location.pathname !== "/adminProfile" && (
          <Row className="user_bar w-100">
            <Col xs={24} sm={24} md={24} lg={5} xl={4} className="mb-20">
              {user?.img ? (
                <div
                  className="img-perfil"
                  style={{
                    backgroundImage: `url(${config.server_ip}media/${user?.img})`,
                  }}
                ></div>
              ) : (
                <img
                  src={userDefault}
                  className="w-100"
                  style={{ maxWidth: "200px" }}
                />
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} className="info_user">
              <Row className="welcome_text">
                <p>Bem-vindo</p>
              </Row>
              <Row className="user_name">
                <p>{user?.name}</p>
              </Row>
              <Row className="user_profession">
                <p>
                  {!user?.is_admin && !user?.is_viewer
                    ? user?.profession_name
                    : user?.is_admin
                    ? "Administrador"
                    : "Viewer"}
                </p>
              </Row>
              <Row className="edit_profile d-flex align-items-center">
                <p>Editar perfil</p>
                <div
                  className="icon"
                  onClick={() =>
                    navigate(
                      `/${
                        user?.is_admin || user?.is_viewer
                          ? "adminProfile"
                          : "profile"
                      }`
                    )
                  }
                ></div>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={3}
              xl={3}
              className="d-flex flex-column align-items-center justify-content-start"
            >
              {!user?.is_admin && !user?.is_viewer && (
                <>
                  <p className="mb-5 text-center f-16">Nº de Associado</p>
                  <Row className="number">
                    <p className="f-30 bold">
                      {user?.n_member ? user?.n_member : "-"}
                    </p>
                  </Row>
                </>
              )}
              {!user?.is_admin && !user?.is_viewer && (
                <div className="d-flex flex-column">
                  <p className="f-11 mb-5 text-center">Membro desde:</p>
                  <p className="f-18 mt-0 text-center">
                    {user?.member_since
                      ? dayjs(user.member_since).format("DD-MM-YYYY")
                      : ""}
                  </p>
                </div>
              )}
            </Col>
          </Row>
        )}
    </>
  );
}

export default UserBar;
