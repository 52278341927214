import { Col, Row, Button, Tooltip, Modal } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  CalendarOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import DrawerEventsUserList from "../DrawerEventsUserList/DrawerEventsUserList";
import endpoints from "../../../utils/endoinpts";
import eventsSVG from "../../../assets/icons/inscricao-eventos.svg";
import mbImage from "../../../assets/icons/MB.svg";
import mbStepImage from "../../../assets/icons/MB-Step.svg";
import mbWayImage from "../../../assets/icons/MB-Way.svg";
import bankImage from "../../../assets/icons/Bank.svg";
import "./DashboardEvents.css";

export default function DashboardNewEvents({ user }) {
  const [events, setEvents] = useState([]);
  const [eventSelected, setEventSelected] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({});
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [isOpenDrawerEventsList, setIsOpenDrawerEventsList] = useState(false);
  const [isOpenModalEventPayment, setIsOpenModalEventPayment] = useState(false);

  const navigate = useNavigate();

  const hoverContent = (
    <div className="hover_popover">
      Este evento ainda tem pagamento em falta, por favor verifique aqui a sua
      situação.
    </div>
  );

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      handleread();
    }
  }, [user]);

  function handleread() {
    axios
      .get(endpoints.event.readyByUser, {
        params: { id_user: user.id },
      })
      .then((res) => {
        console.log(res);
        setEvents(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleGetPaymentInfo(data) {
    if (data.payment_by === "O(a) próprio(a)") {
      axios
        .get(endpoints.purchase.getPaymentInfo, {
          params: { id: data.id_payment_user },
        })
        .then((res) => {
          setIsOpenModalEventPayment(true);
          setPaymentInfo(res.data[0]);
          setEventSelected(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function handleCloseEvents() {
    setIsOpenDrawerEventsList(false);
    handleread();
  }

  return (
    <Row id="new-events" className="dash_card">
      <DrawerEventsUserList
        show={isOpenDrawerEventsList}
        close={handleCloseEvents}
        events={events}
        user={user}
      />
      <Col
        span={24}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Row align="top">
          <Col span={24}>
            <Row className="ml-0 mr-0">
              <p className="dash_title">Os meus eventos</p>
            </Row>
            <Row className="ml-0 mr-0">
              <Col span={7}>
                <img src={eventsSVG} />
              </Col>
              <Col span={11}></Col>
              <Col span={6}>
                <p className="dash_text bold" align="center">
                  Nº eventos
                </p>
                <p className="dash_text bold f-40 yellow" align="center">
                  {events.length}
                </p>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="mt-20">
            {events.slice(0, 3).map((item, index) => {
              return (
                <Row className="ml-0 mr-0 mb-10" key={index}>
                  <Col span={18}>
                    <p className="dash_text bold yellow">
                      {item.name}
                      {moment(item.enroll_date) === moment() && (
                        <span className="dash_text tag tag-yellow">Novo</span>
                      )}
                      {item.payment_status !== "pago" && (
                        <span className="tag_payment">
                          <Tooltip key={item.id} title={hoverContent}>
                            <InfoCircleOutlined
                              className="red_info"
                              onClick={() => handleGetPaymentInfo(item)}
                            />
                          </Tooltip>
                        </span>
                      )}
                    </p>
                    <p className="dash_text grey date">
                      <CalendarOutlined />{" "}
                      {moment(item.date_start).format("DD/MM/YYYY")}
                    </p>
                  </Col>
                  <Col span={6} align="center">
                    <EyeOutlined
                      onClick={() => navigate(`/eventDetails/${item.id}`)}
                      className="eye_icon"
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
        <Row align="bottom">
          <Col span={24} align="center">
            <Button
              className="button-yellow"
              onClick={() => setIsOpenDrawerEventsList(true)}
            >
              Os meus eventos
            </Button>
          </Col>
        </Row>
      </Col>

      <Modal
        className="payment_options"
        open={isOpenModalEventPayment}
        onCancel={() => setIsOpenModalEventPayment(false)}
        footer={[]}
      >
        {paymentInfo && (
          <>
            <Row>
              <Col span={24}>
                <p className="f-18 bold mt-0 mb-10">Dados para pagamento:</p>
                <Row className="step_reference" gutter={[24]}>
                  <Col
                    span={4}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <img
                      src={
                        paymentInfo.method === "mb"
                          ? mbStepImage
                          : paymentInfo.method === "mbway"
                          ? mbWayImage
                          : bankImage
                      }
                    />
                  </Col>
                  {paymentInfo.method === "mb" && (
                    <Col span={20}>
                      <p className="payment_info">
                        <b>Entidade:</b> {paymentInfo.entity}
                      </p>
                      <p className="payment_info">
                        <b>Referência:</b> {paymentInfo.reference}
                      </p>
                      <p className="payment_info">
                        <b>Montante:</b> {paymentInfo.value}€
                      </p>
                      <p className="payment_info date_expiry">
                        <b>Data limite de pagamento:</b>{" "}
                        {paymentInfo.payment_expiry_date}
                      </p>
                    </Col>
                  )}
                  {paymentInfo.method === "mbway" && (
                    <Col span={20}>
                      <p className="payment_info">
                        <b>Nº Telemóvel:</b> {paymentInfo.phone_number}
                      </p>
                      <p className="payment_info">
                        <b>Montante:</b> {paymentInfo.value}€
                      </p>
                    </Col>
                  )}
                  {paymentInfo.method === "debit" && (
                    <Col span={20}>
                      <p className="payment_info">
                        <b>IBAN:</b> {paymentInfo.iban}
                      </p>
                      <p className="payment_info">
                        <b>Nome do banco:</b> {paymentInfo.bank_name}
                      </p>
                      <p className="payment_info">
                        <b>Nome do/a Associado/a:</b>{" "}
                        {paymentInfo.bank_user_name}
                      </p>
                      <p className="payment_info">
                        <b>Montante:</b> {paymentInfo.value}€/
                        {paymentInfo.payment_plan_type}
                      </p>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </Row>
  );
}
