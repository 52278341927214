import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Space,
  Input,
  Table,
  Avatar,
  Tooltip,
  Modal,
  Button,
} from "antd";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  UserOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import axios from "axios";
import moment from "moment";

import endpoints from "../../../../utils/endoinpts";
import userDefault from "../../../../assets/images/user-default.svg";

import Create from "../Create/Create";
import Update from "../Update/Update";
import Delete from "../Delete/Delete";
import Subscribers from "../Subscribers/Subscribers";

import internSVG from "../../../../assets/icons/Intern.svg";
import mgfSVG from "../../../../assets/icons/MGF.svg";
import nurseSVG from "../../../../assets/icons/Nurse.svg";
import othersSVG from "../../../../assets/icons/Others.svg";
import clinicalSVG from "../../../../assets/icons/Clinical-Secretary.svg";
import nonAssociatedSVG from "../../../../assets/icons/non-associated.svg";

import "./List.css";
import config from "../../../../utils/config";

/* table config */
const columns = [
  {
    title: "",
    dataIndex: "image",
    width: 76,
  },
  {
    title: "Evento",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    width: 400,
  },
  {
    title: "Profissões",
    dataIndex: "professions",
  },
  {
    title: "",
    dataIndex: "users",
    width: 200,
  },
  {
    title: "Data limite",
    dataIndex: "date",
  },
  {
    title: "Ativo",
    dataIndex: "active",
    width: 50,
  },
  {
    title: "",
    dataIndex: "action",
    width: 120,
  },
];

const imagesProfessions = [
  mgfSVG,
  internSVG,
  nurseSVG,
  clinicalSVG,
  othersSVG,
  nonAssociatedSVG,
];

export default function List({ user }) {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isOpenDrawerAddEvent, setIsOpenDrawerAddEvent] = useState(false);
  const [isOpenDrawerEditEvent, setIsOpenDrawerEditEvent] = useState(false);
  const [isOpenModalDeleteEvent, setIsOpenModalDeleteEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});

  useEffect(() => {
    handleread();
  }, []);

  function handleread() {
    setIsLoading(true);
    axios
      .get(endpoints.event.readAdmin)
      .then((response) => {
        let eventsData = response.data.events;
        let eventsUsersData = response.data.eventUsers;
        let eventsProfessionsData = response.data.eventProfessions;
        for (let i = 0; i < eventsData.length; i++) {
          let auxUsersEvent = [];
          let auxProfessionsEvent = [];

          for (let z = 0; z < eventsUsersData.length; z++) {
            if (eventsUsersData[z].id_event === eventsData[i].id) {
              auxUsersEvent.push(eventsUsersData[z]);
            }
          }

          for (let y = 0; y < eventsProfessionsData.length; y++) {
            if (eventsProfessionsData[y].id_event === eventsData[i].id) {
              auxProfessionsEvent.push(eventsProfessionsData[y]);
            }
          }

          eventsData[i].users = auxUsersEvent;
          eventsData[i].professions = auxProfessionsEvent;
        }

        setEvents(eventsData);
        handlePrepareData(eventsData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /* very important */
  function handlePrepareData(data) {
    let newArrayItens = [];
    data.map((item, index) => {
      newArrayItens.push({
        key: item.id,
        image: item.image ? (
          <img
            src={`${config.server_ip}media/${item.image}`}
            className="table__image"
          />
        ) : (
          <img
            src={
              "https://static.independent.co.uk/s3fs-public/thumbnails/image/2016/02/05/13/porto-alamy.jpg"
            }
            className="table__image"
          />
        ),
        name: item.name,
        professions: (
          <span>
            <Avatar.Group
              maxCount={10}
              size="large"
              maxPopoverTrigger={""}
              maxStyle={{
                color: "#4A4C4D",
                backgroundColor: "#F3F6F9",
              }}
            >
              {item.professions.map((item, index) => (
                <Tooltip title={item.name}>
                  <Avatar src={imagesProfessions[item.id - 1]} />
                </Tooltip>
              ))}
            </Avatar.Group>
          </span>
        ),
        users: (
          <span>
            <Avatar.Group
              maxCount={5}
              size="large"
              maxPopoverTrigger={""}
              maxStyle={{
                color: "#4A4C4D",
                backgroundColor: "#F3F6F9",
              }}
            >
              {item.users.map((item, index) => (
                <Tooltip title={item.name ? item.name : item.guest_name}>
                  <Avatar
                    src={
                      item.img
                        ? `${config.server_ip}media/${item.img}`
                        : userDefault
                    }
                  />
                </Tooltip>
              ))}
            </Avatar.Group>
          </span>
        ),
        date: moment(item.enroll_date_limit).format("DD-MM-YYYY"),
        active: item.is_active ? (
          <CheckCircleTwoTone twoToneColor="#73a91b" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#D1000E" />
        ),
        action: (
          <>
            <UserOutlined
              className="icon icon-edit mr-20"
              onClick={() => handleShowModal(item)}
            />
            {user.is_viewer ? (
              <EyeOutlined
                className="icon icon-edit mr-20"
                onClick={() => handleSelectEvent(item)}
              />
            ) : (
              <EditOutlined
                className="icon icon-edit mr-20"
                onClick={() => handleSelectEvent(item)}
              />
            )}
            <DeleteOutlined
              className="icon icon-delete"
              onClick={() => handeSelectToDeleteEvent(item)}
            />
          </>
        ),
        full_data: item,
      });
    });

    setTableData(newArrayItens);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  function handleSearch(e) {
    let value = e.target.value;
    setSearchText(value);
    const newData = events.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    handlePrepareData(newData);
  }

  function handleShowModal(data) {
    setSelectedEvent(data);
    setIsModalOpen(true);
  }

  function handleSelectEvent(data) {
    setSelectedEvent(data);
    setIsOpenDrawerEditEvent(true);
  }

  function handleCloseDrawer() {
    setIsOpenDrawerAddEvent(false);
    setIsOpenDrawerEditEvent(false);
    setSelectedEvent({});
    handleread();
  }

  function handleCloseModalEventUsers() {
    setSelectedEvent({});
    setIsModalOpen(false);
  }

  function handeSelectToDeleteEvent(data) {
    setSelectedEvent(data);
    setIsOpenModalDeleteEvent(true);
  }

  function handleCloseModalDeleteEvent() {
    setIsOpenModalDeleteEvent(false);
    setSelectedEvent({});
    handleread();
  }

  return (
    <Row id="admin-list-events" className="dash_card">
      <Create open={isOpenDrawerAddEvent} close={handleCloseDrawer} />
      <Update
        user={user}
        data={selectedEvent}
        open={isOpenDrawerEditEvent}
        close={handleCloseDrawer}
      />
      <Delete
        data={selectedEvent}
        open={isOpenModalDeleteEvent}
        close={handleCloseModalDeleteEvent}
      />
      <Subscribers
        open={isModalOpen}
        close={handleCloseModalEventUsers}
        event={selectedEvent}
      />
      <Col span={24} className="p-20">
        <Row className="ml-0 mr-0">
          <Col span={16} className="d-flex align-items-center">
            <p className="title bold m-0">Eventos</p>
          </Col>
          <Col
            span={8}
            className="d-flex flex-column justify-content-end w-100"
          >
            <Row className="d-flex jc-center ai-center flex-nowrap">
              <Button size="large" className="mr-10" onClick={handleread}>
                <ReloadOutlined />
              </Button>
              {!user.is_viewer ? (
                <Button
                  className="mr-10"
                  size="large"
                  onClick={() => setIsOpenDrawerAddEvent(true)}
                >
                  Adicionar
                </Button>
              ) : null}
              <Input
                allowClear
                type="text"
                size="large"
                onChange={handleSearch}
                placeholder="Procurar eventos"
                suffix={<SearchOutlined />}
                value={searchText}
              />
            </Row>
          </Col>
        </Row>
        <Row className="ml-0 mr-0 mt-20">
          <Col span={24}>
            <Table
              className="table"
              dataSource={tableData}
              columns={columns}
              loading={isLoading}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
