import React, { useEffect, useState } from "react";
import {
  Outlet,
  Route,
  useLocation,
  Link,
  useNavigate,
} from "react-router-dom";
import { Layout, Row, Col, Badge, message } from "antd";
import DrawerShoppingCart from "../../components/UserComponents/DrawerShoppingCart/DrawerShoppingCart";
import logoUSF from "../../assets/images/USF-AN.svg";
import svgProfile from "../../assets/icons/svg-perfil.svg";
import svgLogout from "../../assets/icons/svg-logout.svg";
import svgSettings from "../../assets/icons/svg-settings.svg";
import svgSite from "../../assets/icons/svg-site.svg";
import svgCart from "../../assets/icons/svg-carrinho.svg";
import GuestDrawerShoppingCart from "../../components/GuestComponents/GuestDrawerShoppingCart/GuestDrawerShoppingCart";
const { Header, Sider, Content, Footer } = Layout;

import "./GuestLayout.css";
import axios from "axios";
import endpoints from "../../utils/endoinpts";
import Loading from "../Loading/Loading";

export default function GuestLayout() {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenGuestShoppingCartDrawer, setIsOpenGuestShoppingCartDrawer] =
    useState(false);
  const [shoppingCart, setShoppingCart] = useState({ voucher: {}, items: [] });

  const navigate = useNavigate();

  useEffect(() => {
    handleVerifyUser();
  }, []);

  function handleVerifyUser() {
    let token = localStorage.getItem("token");
    if (token) {
      axios
        .get(endpoints.auth.verifyToken, {
          params: { token },
        })
        .then(async (res) => {
          if (res.data.token_valid) {
            message.success({ content: "Já se encontra logado!" });
            if (res.data.user_data.is_admin) {
              navigate("/admin");
            } else {
              navigate("/");
            }
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }

  function handleOpenGuestShoppingCartDrawer() {
    setIsOpenGuestShoppingCartDrawer(true);
  }

  function handleCloseGuestShoppingCartDrawer(data) {
    localStorage.setItem("shopping_cart", JSON.stringify(data));
    setIsOpenGuestShoppingCartDrawer(false);
  }

  function handleUpdateShoppingCart(data) {
    localStorage.setItem("shopping_cart", JSON.stringify(data));
    setShoppingCart(data);
  }

  return (
    <>
      {isLoading && <Loading isLoading={isLoading} />}
      <Layout className="d-flex flex-column jc-center ai-center main_layout">
        <GuestDrawerShoppingCart
          open={isOpenGuestShoppingCartDrawer}
          close={handleCloseGuestShoppingCartDrawer}
          handleUpdateShoppingCart={handleUpdateShoppingCart}
        />
        <Header className="d-flex jc-center ai-center w-100">
          <Row className="d-flex ai-center w-100 container pb-0">
            <Col span={12}>
              <img
                src={logoUSF}
                className="logo_header"
                onClick={() => navigate("/login")}
              />
            </Col>
            <Col span={12}>
              <Row className="icons d-flex jc-end ai-center">
                <div
                  className="icon_website mr-10"
                  onClick={() => window.open("https://usf-an.pt")}
                >
                  <img src={svgSite} />
                </div>
                <Badge count={shoppingCart ? shoppingCart.items.length : 0}>
                  <div
                    className={`icon_settings`}
                    onClick={handleOpenGuestShoppingCartDrawer}
                  >
                    <img src={svgCart} />
                  </div>
                </Badge>
              </Row>
            </Col>
          </Row>
        </Header>
        <Content className={`container d-flex flex-column jc-start ai-center`}>
          <Outlet context={{ shoppingCart, handleUpdateShoppingCart }} />
        </Content>
        <Footer>
          <Row className="w-100">
            <Col span={24} className="text-center footer">
              <p>
                © Copyright 2024. USF-AN. Todos os direitos reservados |{" "}
                <a href="https://usf-an.pt/termos-e-condicoes/" target="_blank">
                  Termos e condições
                </a>{" "}
                |{" "}
                <a href="https://usf-an.pt/termos-e-condicoes/" target="_blank">
                  Política de privacidade
                </a>
              </p>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </>
  );
}
