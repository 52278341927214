import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Col, Row, Space, Input, Button } from "antd";
import axios from "axios";
import moment from "moment";
import { CalendarOutlined } from "@ant-design/icons";

import endpoints from "../../../utils/endoinpts";
import config from "../../../utils/config";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./DashboardListEvents.css";

// import required modules
import { Pagination } from "swiper/modules";

const { Search } = Input;

export default function DashboardListEvents({ user }) {
  const [events, setEvents] = useState([]);
  const [eventsUser, setEventsUser] = useState([]);
  const [eventsFiltered, setEventsFiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(3);

  const navigate = useNavigate();

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      handleread();
    }
  }, [user]);

  function handleread() {
    axios
      .get(endpoints.event.readByProfession, {
        params: { id_profession: user.id_profession },
      })
      .then((res) => {
        let eventsData = res.data.events;
        axios
          .get(endpoints.event.readyByUser, {
            params: { id_user: user.id },
          })
          .then((res) => {
            setEventsUser(res.data);
            setEvents(eventsData);
            setEventsFiltered(eventsData);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /* pagination action */

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  /* search bar action */
  function handleSearchEvents(value) {
    let text = value.target.value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const newArrayItens = events.filter((item) =>
      item.name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(text.toLowerCase())
    );
    setEventsFiltered(newArrayItens);
  }

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  return (
    <Row id="list-events" className="dash_card mt-20" gutter={[24]}>
      <Col span={24}>
        <Row className="ml-0 mr-0">
          <Col
            xs={24}
            sm={24}
            md={18}
            lg={18}
            xl={18}
            className="d-flex ai-center mb-20"
          >
            <p className="dash_title mb-0">Eventos</p>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={6}
            lg={6}
            xl={6}
            align="end"
            className="mb-20"
          >
            <Search
              size="large"
              placeholder="Procurar..."
              allowClear
              onChange={(e) => handleSearchEvents(e)}
            />
          </Col>
        </Row>
        {eventsFiltered && eventsFiltered.length > 0 ? (
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            modules={[Pagination]}
            pagination={pagination}
            className="mySwiper"
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
          >
            {eventsFiltered.map((item, index) => {
              return (
                <SwiperSlide>
                  <div className={"w-100 item item-" + index} key={item.id}>
                    <div className="item_content">
                      <div className="item_image">
                        <img
                          src={`${config.server_ip}media/${item.image}`}
                          alt={`${item.image}`}
                        />
                      </div>
                      <h3 className="item_title">{item.name}</h3>
                      <div className="item_date">
                        <div>
                          <CalendarOutlined />{" "}
                          {moment(item.date_start).format("DD/MM/YYYY")}
                        </div>
                      </div>
                      <div
                        className="item_description"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></div>
                    </div>
                    {user.payment_valid ? (
                      <Button
                        className="item_cta button-yellow"
                        onClick={() => navigate(`/eventDetails/${item.id}`)}
                      >
                        {eventsUser.length > 0
                          ? eventsUser.filter(
                              (value) => Number(value.id) === Number(item.id)
                            )[0]
                            ? "Já está inscrito"
                            : "Inscrever"
                          : "Inscrever"}
                      </Button>
                    ) : null}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <div className="empty_search">Não foi encontrado nenhum evento!</div>
        )}
        {/*<Row className='ml-0 mr-0 mt-30' align='center'>
          <Pagination onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={events.length} />
      </Row>*/}
      </Col>
    </Row>
  );
}
