import React, { useState, useEffect } from 'react'
import { Table, Drawer, Row, Col, Input, Button, Modal } from 'antd'

export default function Cancel({ open, close, submit }) {
  return (
    <Modal
      className="user-payment-modal"
      open={open}
      onCancel={close}
      title="Cancelar pagamento"
      footer={[
        <Button
          className="payment_button"
          key="submit"
          type="primary"
          onClick={submit}
        >
          Sim
        </Button>,
        <Button
          className="payment_button back"
          key="submit"
          type="primary"
          onClick={close}
        >
          Não
        </Button>

      ]}
      maskClosable={false}
    >
      <Row>
        <Col span={24}>
          Tem a certeza que deseja cancelar este pagamento?
        </Col>
      </Row>
    </Modal>
  )
}